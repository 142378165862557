<template>
  <div class="share-this">
    <!-- This is the placement code ShareThis provides. -->
    <div class="sharethis-inline-share-buttons custom-st" :data-url="url" :data-image="imageUrl"></div>
  </div>
</template>

<script>
export default {
  props : {
    url : {
      type    : String,
      default : ''
    },
    imageUrl : {
      type    : String,
      default : ''
    }
  },
  mounted() {
    const st = window.__sharethis__

    if (!st) {
      const script = document.createElement('script')
      script.src
        = 'https://platform-api.sharethis.com/js/sharethis.js#property=XXXXXXX&product=image-share-buttons'
      document.body.appendChild(script)
    } else if (typeof st.initialize === 'function') {
      st.href = window.location.href
      st.initialize()
    }
  }
}
</script>
<style scoped lang="scss">
  @media only screen and (max-width: 767px) {
    .custom-st {
      text-align: center !important;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
</style>
