<template>
<main class="contents_main">
  <div class="contents">
    <section id="horse_detail">
      <div class="row b_detail">
        <div class="horse_prof">
          <p v-if="item && item.free_field" class="u-pr12">{{item.free_field.nickname}}</p>
          <p v-if="item && item.free_field" class="u-mr10-edit">{{getGender(item.free_field.gender)}}</p>
          <p
            class="u-mr10-edit"
            v-if="item && item.free_field"
          >
            {{isTosaiBa(item.free_field) ? $t('ITEM_ROW_AGE_0_LABEL') : ((typeof item.free_field.age) !== 'undefined' ? item.free_field.age + $t('TOP_PAGE_AGE_LABEL') : '')}}
          </p>
          <p v-if="item && item.free_field && !isTosaiBa(item.free_field)">{{getCategory(item.free_field.category, 3, 1)}}</p>
        </div>
        <div class="detail_fav" v-if="item && item.attention_info">
          <button
           :value="$t('ITEM_SEARCH_FAVORITE_LABEL')"
           class="horse_li_fav btn_fav"
           :disabled="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN) === null"
           :data-fav="isFavorite ? 'on' : 'off'"
           @click.stop="favoriteItem(item.attention_info.is_favorited)"
          />
        </div>
      </div>
      <div class="horse_prof_tag" v-if="item">
        <p v-if="String(item.free_field.southkanto_moveable) === '1'">{{$t('ITEM_SEARCH_SOUTHKANTO_MOVEABLE_LABEL') + $t('ITEM_SEARCH_KA_LABEL')}}</p>
        <p v-if="item.free_field.jradirt_win > 0">{{$t('DETAIL_ITEM_JRA_DIRT_LABEL') + item.free_field.jradirt_win + $t('DETAIL_ITEM_JRA_DIRT_WIN_LABEL')}}</p>
        <p v-if="String(item.free_field.foreign_horse) === '1'">{{$t('DETAIL_ITEM_FOREIGN_HORSE_LABEL')}}</p>
        <p v-if="item.free_field.recommend_comment">{{item.free_field.recommend_comment}}</p>
        <div class="reload">
          <button
            v-if="item.bid_status && item.bid_status.can_bid"
            :value="$t('ITEM_SEARCH_REFRESH_BUTTON_LABEL')"
            class="price_reload"
            @click="refreshItemForDetail"
          >
            {{$t('ITEM_SEARCH_REFRESH_BUTTON_LABEL')}}<span class="material-symbols-outlined">cached</span>
          </button>
        </div>
      </div>
    </section>
    <section id="horse_detail_contents" class="" v-if="item">
      <div class="row">
        <div class="detail_visual">
          <Gallery :images="item.image" :exhibitionItemNo="String(item.exhibition_item_no)"/>
        </div>
        <div class="detail_auction">
          <v-tooltip top
            :color="((errors.length > 0 && errors[0].success) ? 'success' : 'error')"
            v-model="errors.length">
            <template v-slot:activator="{ on, attrs }">
              <div>
                <form class="detail_form" v-on:submit.prevent="">
                  <div class="detail_row" v-on:submit.prevent="">
                    <p class="c-b01 auction_th">{{$t("ITEM_ROW_CURRENT_PRICE_LABEL")}}</p>
                    <p class="now_price u-mr10">{{item && item.bid_status ? item.bid_status.current_price.numberString() : ''}}<span class="u-em8">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</span></p>
                    <ul class="d-flex flex-column" v-if="item && item.bid_status">
                      <li class="tax_price">{{'(' + $t('DETAIL_BID_PRICE_TAX_LABEL') + ' ' + getPriceIncludeTax(item.bid_status.current_price, item.bid_status.tax_rate) + $t('ITEM_ROW_PRICE_CURRENCY_MARK') + ')'}}</li>
                    </ul>
                  </div>
                  <div class="detail_row u-mt10 align-items-center" v-if="item && item.bid_status && item.bid_status.can_bid">
                    <p class="c-b01 auction_th u-pv15">{{$t("ITEM_ROW_BID_PRICE_LABEL")}}</p>
                    <div class="bid_input_con">
                      <div class="bid_input_btn subtra-btn" v-if="item.bid_status && item.bid_status.can_bid">
                        <a
                          :disabled="item.bid_status && !item.bid_status.can_bid"
                          @click="subtractPitch(item.bid_status.pitch_width)"
                        >
                          <span class="material-symbols-outlined">remove</span>
                        </a>
                      </div>
                      <input
                        @focus="errors=[]"
                        v-bind:class="{
                          'bid_input': true,
                          'no-spin': true,
                          'err': (errors.length > 0 && !errors[0].success)
                        }"
                        :disabled="item.bid_status && !item.bid_status.can_bid"
                        v-model="inputBidPrice"
                        type="text"
                        maxLength="9"
                        v-on:keypress="isNumber($event)"
                        v-on:focusin="inputBidPrice = onFocusin_start_price($event)"
                        v-on:focusout="inputBidPrice = onFocusout_start_price($event)"
                      >
                      <div class="bid_input_btn add-btn" v-if="item.bid_status && item.bid_status.can_bid">
                        <a
                          :disabled="item.bid_status && !item.bid_status.can_bid"
                          @click="addPitch(item.bid_status.pitch_width)"
                        >
                          <span class="material-symbols-outlined">add</span>
                        </a>
                      </div>
                      <p class="bid_input_en">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</p>
                    </div>
                  </div>
                  <div class="detail_row"></div>
                  <div class="bid_submit" v-if="item && item.bid_status && item.bid_status.can_bid">
                    <div class="btn_radius">
                      <button
                        type="button"
                        :value="$t('ITEM_ROW_BID_BUTTON_LABEL')"
                        class="bid_submit_btn"
                        deta-toggle="modal"
                        @click="bidItemClick(grandParent.typeTag)"
                      >
                        {{$t("ITEM_ROW_BID_BUTTON_LABEL")}}
                      </button>
                    </div>
                  </div>
                  <div class="bid_submit" v-if="item && item.bid_status && item.bid_status.is_cancel">
                    <p class="bid_cancel_txt">{{$t("ITEM_ROW_ITEM_CANCELLED_TEXT")}}</p>
                  </div>
                  <div class="bid_row u-fw700 u-ai-c" v-if="item && item.bid_status && !item.bid_status.started">
                    <p class="c-b01 auction_th">{{$t('DETAIL_ITEM_BID_REMAINING_TIME')}}</p>
                    <p class="bid_row flex-wrap last_time">{{$t('DETAIL_ITEM_BID_BEFORE_START_LABEL')}}</p>
                  </div>
                  <div class="bid_row u-fw700" v-else-if="item && item.bid_status && item.bid_status.can_bid">
                    <p class="c-b01 auction_th">{{$t('DETAIL_ITEM_BID_REMAINING_TIME')}}</p>
                    <p class="bid_row flex-wrap last_time">
                      <ExtendCountDown
                        :isTopScreen="false"
                        :remainingSeconds="item.bid_status.remaining_seconds"
                        :endDatetime="$t('ITEM_ROW_ENDED_STATUS_LABEL')"
                        :key="item.bid_status.remaining_seconds"
                        :extending="item.bid_status.extending"
                        :daySplitter="$t('DETAIL_ITEM_BID_COUNTDOWN_DAY_LABEL')"
                        :hourSplitter="$t('DETAIL_ITEM_BID_COUNTDOWN_HOUR_LABEL')"
                        :minuteSplitter="$t('DETAIL_ITEM_BID_COUNTDOWN_MINUTE_LABEL')"
                        :secondSplitter="$t('DETAIL_ITEM_BID_COUNTDOWN_SECOND_LABEL')"
                      />
                    </p>
                  </div>
                  <div class="bid_row u-fw700 u-ai-c" v-else>
                    <p class="c-b01 auction_th">{{$t('DETAIL_ITEM_BID_REMAINING_TIME')}}</p>
                    <p class="bid_row flex-wrap last_time" v-if="!$store.state.loading"><span class="u-mt16 u-mb16">{{$t('ITEM_ROW_ENDED_STATUS_LABEL')}}</span></p>
                  </div>
                  <div class="bid_row flex-wrap" v-if="item && item.bid_status">
                    <p class="c-b01 auction_th">{{$t('DETAIL_ITEM_BID_END_TIME_LABEL')}}</p>
                    <p class="end_time">{{item.bid_status.end_datetime | dateTimeFormat}}{{item.bid_status.remaining_seconds > 0 ? $t("ITEM_ROW_END_YOTEI_STATUS_LABEL") : $t("ITEM_ROW_ENDED_STATUS_LABEL")}}</p>
                    <p style="margin-left:auto; text-align:right" v-if="item.bid_status.extending">{{$t('LINEUP_ITEMS_AUCTION_AUTO_EXTENDED_LABEL')}}</p>
                  </div>
                </form>
              </div>
            </template>
            <div :class="errors.length>0 && errors[0].success ? 'custom-tooltip': ''">
              <div style="padding: 10px;" v-if="errors.length>0">
                <div v-for="(message, index) in errors" :key="index">{{message.errorMessage}}</div>
              </div>
            </div>
          </v-tooltip>

          <div class="history">
            <div class="bid_row flex-wrap">
              <p class="c-b01">{{$t('DETAIL_BID_COUNT_LABEL')}}</p>
              <p class="bid_count c-b01" v-if="item && item.attention_info">{{item.attention_info.apply_bid_count}}</p>
              <p class="bid_history u-pl10">(<a href="#bid_log" :class="bid_histories ? '' : 'no_link'">{{$t('DETAIL_BID_HISTORY_LABEL')}}</a>)</p>
              <p v-if="item && item.bid_status && item.bid_status.is_top_member" class="bid_number" data-bid="top"></p>
              <p v-if="item && item.bid_status && item.bid_status.is_second_member" class="bid_number" data-bid="second"></p>
            </div>
            <div class="bid_row">
              <p class="">{{$t('DETAIL_BID_CURRENT_CANDIDATE_LABEL')}}</p>
              <p v-if="item && item.bid_status && item.bid_status.top_member_nickname"><span>{{getTopMemberNickname(item.bid_status.top_member_nickname)}}</span>{{$t('DETAIL_BID_CURRENT_CANDIDATE_SAN_LABEL')}}</p>
            </div>
            <div class="bid_row">
              <p class="">{{$t('DETAIL_BID_START_DATE_TIME_LABEL')}}</p>
              <p v-if="item && item.bid_status"><span>{{item.bid_status.start_datetime | dateTimeFormat}}</span></p>
            </div>
            <div class="bid_row">
              <p class="">{{$t('DETAIL_BID_START_PRICE_LABEL')}}</p>
              <p v-if="item && item.bid_status">
                <span>{{item.bid_status.lowest_bid_price.numberString() + $t('ITEM_ROW_PRICE_CURRENCY_MARK')}}</span>
                <span>（{{$t('DETAIL_BID_PRICE_TAX_LABEL') + ' ' + getPriceIncludeTax(item.bid_status.lowest_bid_price, item.bid_status.tax_rate) + $t('ITEM_ROW_PRICE_CURRENCY_MARK')}}）</span>
              </p>
            </div>
            <p class="u-fs12 u-mt10" v-if="item.bid_status && item.bid_status.can_bid">{{$t('DETAIL_BID_PRICE_GUILD_LABEL')}}</p>
          </div>
        </div>
      </div>
    </section>

    <section id="" v-if="item">
      <div class="horse_info">
        <div class="horse_info_title">
          <h2>{{$t('DETAIL_PRODUCT_INFORMATION_LABEL')}}</h2>
          <button
           class="pedigree"
           type="button"
           v-bind:class="{'btn_disabled': !item.free_field.basic_info_url}"
           @click="gotoLink(item.free_field.category, item.free_field.basic_info_url)"
          >
            {{getLinkText(item.free_field.category)}}
            <span class="material-symbols-outlined">open_in_new</span>
          </button>
        </div>
        <div
          class="horse_info_text"
          v-for="(free_field, index) in (free_fields || [item.free_field])"
          :key="index"
        >
          <ul>
            <li>
              <span>{{free_field.nickname}}　{{getGender(free_field.gender)}}　{{isTosaiBa(free_field) ? $t('ITEM_ROW_AGE_0_LABEL') : (free_field.age ? free_field.age + $t('TOP_PAGE_AGE_LABEL') : '')}}</span>
            </li>
            <li>
              {{getColor(free_field.color, 3)}}　{{getCategory(free_field.category, 3, 2)}}
            </li>
            <li v-if="free_field.birthday">
              {{free_field.birthday | dateFormat}}{{$t('DETAIL_ITEM_FOREIGN_HORSE_SUBFIX')}}
            </li>
          </ul>
          <ul>
            <li>
              <span class="c-b01">{{$t("DETAIL_FATHER_LABEL")}}</span>　{{free_field.father}}
            </li>
            <li>
              <span class="c-b01">{{$t("DETAIL_MOTHER_LABEL")}}</span>　{{free_field.mother}}
            </li>
            <li>
              <span class="c-b01">{{$t("DETAIL_MATERNAL_GRANDFATHER_LABEL")}}</span>　{{free_field.maternal_grandfather}}
            </li>
          </ul>
          <ul v-if="isShowAchievement(free_field.category)">
            <li>
              <span class="c-b01">{{$t("DETAIL_TOTAL_ACHIEVEMENT_LABEL")}}</span>　{{getTotalAchievement(free_field.total_achievements)}}
            </li>
            <li>
              <span class="c-b01">{{$t("DETAIL_CENTRAL_PRIZE_MONEY_LABEL")}}</span>　{{free_field.central_prize_money ? $options.filters.prizeMoney2String(free_field.central_prize_money) + $t('ITEM_ROW_PRICE_CURRENCY_MANYEN_MARK') : ''}}
            </li>
            <li>
              <span class="c-b01">{{$t("DETAIL_LOCAL_PRIZE_MONEY_LABEL")}}</span>　{{free_field.local_prize_money ? $options.filters.prizeMoney2String(free_field.local_prize_money) + $t('ITEM_ROW_PRICE_CURRENCY_MANYEN_MARK') : ''}}
            </li>
            <li>
              <span class="c-b01">{{$t("DETAIL_WEIGHT_LABEL")}}</span>　{{free_field.weight ? free_field.weight + $t('ITEM_ROW_WEIGHT_KG_MARK') : ''}}
            </li>
          </ul>
          <ul>
            <li>
              <span class="c-b01">{{$t('DETAIL_ITEM_REGISTER_SELLER_LABEL')}}</span>　{{free_field.register_seller || ''}}
            </li>
          </ul>
        </div>
        <div class="horse_info_title" v-if="item.free_field.remarks">
          <h2>{{$t('DETAIL_ITEM_HORSE_MEMO_LABEL')}}</h2>
        </div>
        <div class="horse_info_text" v-if="item.free_field.remarks">
          <div class="horse_introduction">
            <p style="white-space: pre-line">{{item.free_field.remarks}}</p>
          </div>
        </div>
        <a class="anchor" id="bid_log" v-if="bid_histories"></a>
        <div class="horse_info_title" v-if="bid_histories">
          <h2>{{$t('DETAIL_BID_HISTORY_TITLE')}}</h2>
        </div>
        <div class="horse_info_text" v-if="bid_histories">
          <table class="bid_log">
            <tr>
              <th>{{$t('DETAIL_BID_HISTORY_BID_TIME')}}</th>
              <th>{{$t('DETAIL_BID_HISTORY_BID_PRICE')}}</th>
              <th>{{$t('DETAIL_BID_HISTORY_NICKNAME')}}</th>
            </tr>
            <tr v-for="(history, index2) in bid_histories" :key="index2">
              <td class="u-ta-c">{{history.create_datetime | dateTimeFormat}}</td>
              <td>{{(index2 === 0 && item && item.bid_status ? item.bid_status.current_price : history.bid_price) | number2string('', $t('ITEM_ROW_PRICE_CURRENCY_MARK'))}}</td>
              <td>{{history.nickname}}</td>
            </tr>
          </table>
        </div>
        <!-- <div class="horse_info_text" v-if="item && item.image">
          <MediaBox :images="item.image"/>
        </div> -->
      </div>
    </section>
    <section>
      <div class="return_top">
        <a
          class="btn_return"
          @click="closeTab"
        >{{$t('DETAIL_CLOSE_BUTTON_LABEL')}}</a>
      </div>
    </section>
  </div>

  <bid-confirm-dialog
    :parent="$data"
    :confirmBidDialog="confirmBidDialog"
    :bidConfirm="bidConfirm"
    :closeBidConfirmDialog="closeBidConfirmDialog"
    :getPriceIncludeTax="getPriceIncludeTax"
    :bidConfirmItems="bidConfirmItems"
    :bidConfirmDialogMode="bidConfirmDialogMode"
    :bidConfirmSuccess="bidConfirmSuccess"
    :errors="bidConfirmMessages"
    :bidConfirmSuccessMessage="bidConfirmSuccessMessage"
  />

  <div class="pagearrow" v-if="items && items.length > 1">
    <a class="prev_page" v-if="itemIndex > 0" @click="prevBtnClicked">{{$t('DETAIL_ITEM_PREV_BUTTON_LABEL')}}</a>
    <a class="is-sp blank_page" v-else></a>
    <a class="next_page" v-if="items.length > itemIndex + 1 || isMoreLimit" @click="nextBtnClicked">{{$t('DETAIL_ITEM_NEXT_BUTTON_LABEL')}}</a>
    <a class="is-sp blank_page" v-else></a>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import Gallery from '@/components/Gallery/Gallery'
import MediaBox from '@/components/Gallery/MediaBox'
import moment from 'moment'
import BidConfirmDialog from './BidConfirmDialog'
import ExtendCountDown from './ExtendCountDown'
import Filter from './Filter.js'
import RowBid from './RowBid.js'
import ShareThis from './ShareThis'
import WebSocket from './WebSocket.js'
import BidConfirm from './bidConfirm.js'

export default {
  components : {
    Gallery,
    MediaBox,
    ExtendCountDown,
    ShareThis,
    BidConfirmDialog
  },
  props : {
    grandParent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Function,
      default : Object
    },
    downloadImages : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    },
    refreshItem : {
      type    : Function,
      default : Object
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    }
  },
  mixins : [Filter, RowBid, WebSocket, BidConfirm, CommonFilters],
  data() {
    return {
      scripts : [
        /*
         * 'slider.js',
         * 'slick.min.js'
         */
      ],
      item : {
        free_field : {},
        image      : []
      },
      free_fields   : [],
      bid_histories : [],
      itemIndex     : null,

      isShareThisVisible : true,

      favoriteChangedItems : null
    }
  },
  mounted() {
    Methods.addExternalScripts(this.scripts)
  },
  methods : {
    downloadItemImages() {
      this.$store.state.loading = true
      const params = {
        exhibitionItemNo : this.item.exhibition_item_no
      }
      this.downloadImages(params).then(data => {
        this.$store.state.loading = false
        location.href = data.url
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    refreshItemForDetail() {
      // Set detail page title
      let title = this.$route.meta.title
      title = this.item.free_field.nickname ? (`${this.item.free_field.nickname}｜${title}`) : title
      if (title) {
        document.title = title
      }

      // Refresh detail item
      const params = {
        exhibitionItemNo : this.$route.params.id
      }
      this.$store.state.loading = true

      console.log('refreshItemForDetail')
      this.refreshItem(params).then(data => {
        this.$store.state.loading = false
        this.free_fields = data.free_fields
        this.bid_histories = data.bid_histories
        this.item.image = data.images ? data.images : this.item.image
        this.item.pdfs = data.pdfs ? data.pdfs : this.item.pdfs
        this.item.bid_status = data.bid_status ? Object.assign(this.item.bid_status, data.bid_status) : this.item.bid_status
        this.isFavorite = data.is_favorited

        // Reload displaying bid price
        this.inputBidPrice = this.getBidPrice()

        // Update favorite
        if (this.item && this.item.attention_info) {
          this.item.attention_info.is_favorited = data.is_favorited
        }
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    moment(val) {
      return moment(val)
    },
    getShareUrl() {
      const href = window.location.href
      const url = href.split('?')
      return url && url.length > 1 ? url[0] : href
    },
    getShareImageUrl() {
      let url = ''
      if (this.item.image && this.item.image.length > 0) {
        url = `${window.location.origin}/${this.item.image[0].postar_file_path ? this.item.image[0].postar_file_path : this.item.image[0].file_path}`
      }
      return url
    },
    nextBtnClicked() {
      this.$routerGo(this.$route.name, this.items[this.itemIndex + 1].exhibition_item_no, {filter : this.$route.query.filter})
      this.isShareThisVisible = false
      this.$nextTick(function() {
        this.isShareThisVisible = true
      })
    },
    prevBtnClicked() {
      this.$routerGo(this.$route.name, this.items[this.itemIndex - 1].exhibition_item_no, {filter : this.$route.query.filter})
      this.isShareThisVisible = false
      this.$nextTick(function() {
        this.isShareThisVisible = true
      })
    },
    closeTab() {
      window.close()
    },
    updateFavoriteChangedItems(favItems) {
      this.favoriteChangedItems = favItems
    },
    getTotalAchievement(val) {
      let ret = ''
      if (val === '0-0-0-0') {
        return this.$t('DETAIL_ITEM_JRA_DIRT_NO_MATCH_LABEL')
      }
      if (val && val.length > 0) {
        let total = 0
        const vaList = val.split('-')
        vaList.map(x => {
          total += Number(x)
        })
        const win = vaList[0]
        ret = total + this.$t('DETAIL_ITEM_JRA_DIRT_MATCH_LABEL') + win + this.$t('DETAIL_ITEM_JRA_DIRT_WIN_LABEL')
        ret = `${ret}[${val}]`
      }
      return ret
    },
    gotoLink(catId, baseUrl) {
      if (baseUrl) {
        const tmp = this.getCategory(catId, 4, 0)
        let url = null
        if (tmp === '0') {
          url = baseUrl
        } else {
          url = `${baseUrl}record/`
        }
        console.log('url: ', url)
        window.open(url, '_blank')
      }
    },
    getLinkText(catId) {
      const tmp = this.getCategory(catId, 4, 0)
      if (tmp === '0') {
        return this.$t('DETAIL_ACHIEVEMENT_REPORT_BUTTON_LABEL0')
      } else {
        return this.$t('DETAIL_ACHIEVEMENT_REPORT_BUTTON_LABEL1')
      }
    },
    isShowAchievement(catId) {
      const tmp = this.getCategory(catId, 4, 1)
      if (tmp === '1') {
        return true
      } else {
        return false
      }
    },
    getTopMemberNickname(nickname) {
      if (nickname && nickname.length > 20) {
        return `${nickname.substring(0, 20)}...`
      }
      return nickname || ''
    },
  },
  watch : {
    '$route.params.id'(newVal, oldVal) {
      console.log('watch params.id ', oldVal, newVal)
      this.errors = []
      if (String(newVal) !== String(oldVal)) {
        this.getCurrentItem()
      }
    },
    '$i18n.locale'() {
      console.log('watch $i18n.locale ', this.$i18n.locale)
      this.refreshItemForDetail()
    },
    // Update favorite status
    'favoriteChangedItems'(fvItems) {
      console.log('Fvorite changed items: ', fvItems)
      this.reloadFavoriteChangedItems(fvItems)
    }
  }
}
</script>
<style scoped lang="scss">
  dl dd {
    line-break: anywhere;
  }
  .row {
    /* margin: 0 -15px !important; */
    margin-top: 0px !important;
    margin-right: inherit !important;
    margin-bottom: 0px !important;
    margin-left: inherit !important;
  }
  .v-application p {
    margin-bottom: initial !important;
  }
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  .bid_input_en {
    margin-bottom: 0.8em !important;
  }
  .btn_return, .next_page, .prev_page {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .detail_fav button.btn_fav[data-fav="on"]:before {
    color: #FFCE00;
  }
  .detail_fav button.btn_fav:disabled {
    color: #94A5B2;
    // cursor: pointer;
  }
  .btn_disabled {
    background-color: #f0f7ff !important;
    color: #94A5B2 !important;
    cursor: not-allowed;
  }
  .bid_cancel_txt {
    color: #f00;
    text-align: center;
    font-size: 1.3em;
  }
  @media screen and (max-width: 768px) {
    .bid_cancel_txt {
      font-size: 1.2em;
    }
    .u-mr10-edit {
      margin-right: 3px !important;
    }
  }
  .horse_introduction p {
    padding-top: 0 !important;
  }
  .u-pr12{
    padding-right: 12px !important;
  }
  @media screen and (min-width: 769px) {
    .u-mr10-edit {
      margin-right: 10px !important;
    }
  }
  .reload, .horse_prof_tag p {
    margin-bottom: 0.2rem !important;
    margin-top: 0.2rem !important;
  }
  .horse_prof_tag {
    margin-top: 0 !important;
  }

  @media screen and (max-width: 768px) {
    .tax_price{
      padding-top: 0.6em !important;
    }
    .auction_th {
      font-size: 1.1em !important;
    }
    .bid_row .auction_th {
      margin-right: 20px !important;
    }
    .now_price {
      font-size: 2em !important;
    }
    .last_time {
      font-size: 1.6em !important;
    }
    .history .bid_row p:first-child {
      margin-right: 10px !important;
      min-width: 10rem;
      width: auto;
    }
  }
  .u-ai-c {
    align-items: center !important;
  }
  .u-mb16 {
    margin-bottom: 16px;
  }
  .u-mt16 {
    margin-top: 16px;
  }
  a.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
  @media screen and (max-width: 1024px) {
    a.anchor {
      top: -40px;
    }
  }
  .bid_history .no_link {
    color: inherit !important;
    cursor: default;
  }
  @media screen and (max-width: 768px) {
    .bid_log {
      table-layout: fixed;
    }
    .bid_log tr td:first-child{
      width: 140px;
    }
    .bid_log tr td:nth-child(2){
      width: 90px;
    }
    .bid_log tr td:nth-child(3){
      overflow-wrap: anywhere;
      width: auto;
    }
    .bid_log tr td:last-child {
      text-align: left !important;
    }
  }
</style>

<style scoped src="@/assets/css/contents01.css"></style>
