<template>
  <div class="vjs-custom-skin">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import './video_custom_theme.css'

export default {
  name  : 'VideoPlayer',
  props : {
    src : {
      type    : String,
      default : ''
    },
    poster : {
      type    : String,
      default : ''
    },
    isPause : {
      type    : String,
      default : null
    }
  },
  data() {
    return {
      player          : null,
      default_options : {
        autoplay : false,
        controls : true,
        preload  : 'none',
        sources  : [
        /*
         *   {
         *     src: '/path/to/video.mp4',
         *     type: 'video/mp4'
         *   }
         */
        ]
      }
    }
  },
  mounted() {
    console.log('mounted reloadVideo:', this.src)
    this.reloadVideo()
  },
  methods : {
    pause() {
      if (this.player) {
        this.player.pause()
      }
    },
    reloadVideo() {
      const sources = {
        src : this.src,
        // Type: 'video/mp4'
      }
      const options  = Object.assign({}, this.default_options)
      options.poster = this.poster
      options.sources.push(sources)
      // Console.log('options: ', options)
      this.player = videojs(this.$refs.videoPlayer, options, () => {
        this.player.log('onPlayerReady', this)
      })
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  watch : {
    'isPause'() {
      console.log('pause: ', this.isPause)
      if (this.isPause !== null) {
        this.pause()
      }
    },
    'src'(val) {
      console.log('watch reloadVideo:', this.src)
      this.reloadVideo()
    }
  }
}
</script>
