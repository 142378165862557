<template>
<div class="content_wrap u-pv30" v-if="itemsCountInitial > 0">
  <h2 class="auction_title u-ta-c u-pb30">{{$t('ITEM_SEARCH_TITLE_BAR')}}</h2>
  <div class="search_auction">
    <div class="search_box">
      <div>
        <form>
          <div class="search_acodion">
            <div class="search">
              <p class="u-pv5 u-ph10">
                {{$t('ITEM_SEARCH_CONDITION_TITLE')}}：
                <span v-if="getFiltered(filteredObject).length === 0">{{$t('ITEM_SEARCH_NO_CONDITION_LABEL')}}</span>
                <span v-for="(row, index) in getFiltered(filteredObject)" :key="index">{{(index > 0 ? '、' : '') + row}}</span>
              </p>
              <input type="checkbox" class="menu-btn" id="menu-btn" v-model="searchMenuOpen" @click="searchMenuCheckClick"/>
              <label for="menu-btn" class="menu-icon"><span class="navicon"></span></label>
              <div class="search_area">
                <form>
                  <ul class="search_list">
                    <li class="search_list_check">
                      {{$t('ITEM_SEARCH_SOUTHKANTO_MOVEABLE_LABEL')}}
                      <label class="u-ml10" v-for="(kahi, index) in grandParent.kahiConstants" :key="index">
                        <input type="radio" name="southkanto_moveable" class="checkbox-input" :value="kahi.value1" v-model="filter.southkanto_moveable" v-on:change="searchParamChanged"/>
                        <span>{{kahi.value2}}</span>
                      </label>
                    </li>
                    <li class="search_list_check">
                      {{$t('ITEM_SEARCH_BAD_HABIT_LABEL')}}
                      <label class="u-ml10" v-for="(umu, index) in grandParent.umuConstants" :key="index">
                        <input type="radio" name="bad_habit" class="checkbox-input" :value="umu.value1" v-model="filter.bad_habit" v-on:change="searchParamChanged"/>
                        <span>{{umu.value2}}</span>
                      </label>
                    </li>
                    <li class="search_list_check">
                      {{$t('ITEM_SEARCH_INJURY_HISTORY_LABEL')}}
                      <label class="u-ml10" v-for="(umu, index) in grandParent.umuConstants" :key="index">
                        <input type="radio" name="injury_history" class="checkbox-input" :value="umu.value1" v-model="filter.injury_history" v-on:change="searchParamChanged"/>
                        <span>{{umu.value2}}</span>
                      </label>
                    </li>
                    <li class="search_list_check">
                      {{$t('ITEM_SEARCH_TIMEOVER_LABEL')}}
                      <label class="u-ml10" v-for="(umu, index) in grandParent.umuConstants" :key="index">
                        <input type="radio" name="timeover" class="checkbox-input" :value="umu.value1" v-model="filter.timeover" v-on:change="searchParamChanged"/>
                        <span>{{umu.value2}}</span>
                      </label>
                    </li>
                    <li class="search_list_check">
                      {{$t('ITEM_SEARCH_FAVOURITE_LABEL')}}
                      <label class="u-ml10" v-for="(umu, index) in Array.from(grandParent.umuConstants).sort((a,b) => b.sort_order - a.sort_order)" :key="index">
                        <input type="radio" name="favorite" class="checkbox-input" :value="umu.value1" v-model="filter.favorite" v-on:change="searchParamChanged"/>
                        <span>{{umu.value2}}</span>
                      </label>
                    </li>
                    <li class="filter_list_reset">
                      <a @click="resetSearchItems"><p>　{{$t('ITEM_SEARCH_RESET_BUTTON_LABEL')}}　</p></a>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </form>
        <ul class="sort_list row u-ml0 u-mr0">
          <li class="sort_list_tab sort_label">
            {{$t('ITEM_SEARCH_RESULT_SORT_LABEL')}}
          </li>
          <li class="sort_list_tab select_up-down" @click="sortClick('currentPriceSort')"
            :data-sort="filter.currentPriceSort ? (filter.currentPriceSort === 'asc' ? 'up' : 'down') : ''">
            {{$t('ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL')}}
          </li>
          <li class="sort_list_tab" @click="sortClick('endDatetimeSort')"
            :data-sort="filter.endDatetimeSort ? (filter.endDatetimeSort === 'asc' ? 'up' : 'down') : ''">
            {{$t('ITEM_SEARCH_END_DATETIME_SORT_LABEL')}}
          </li>
          <li class="sort_list_tab" @click="sortClick('ageSort')"
            :data-sort="filter.ageSort ? (filter.ageSort === 'asc' ? 'up' : 'down') : ''">
            {{$t('ITEM_SEARCH_AGE_SORT_LABEL')}}
          </li>
          <li class="sort_list_tab" @click="sortClick('weightSort')"
            :data-sort="filter.weightSort ? (filter.weightSort === 'asc' ? 'up' : 'down') : ''">
            {{$t('ITEM_SEARCH_WEIGHT_SORT_LABEL')}}
          </li>
          <li class="sort_list_tab" @click="sortClick('totalPrizeMoneySort')"
            :data-sort="filter.totalPrizeMoneySort ? (filter.totalPrizeMoneySort === 'asc' ? 'up' : 'down') : ''">
            {{$t('ITEM_SEARCH_TOTAL_PRIZE_MONEY_SORT_LABEL')}}
          </li>
          <li class="sort_list_reload">
            <button :value="$t('ITEM_SEARCH_REFRESH_BUTTON_LABEL')" class="price_reload" @click="reloadPriceItems">
              <p>　{{$t('ITEM_SEARCH_REFRESH_BUTTON_LABEL')}}　<span class="material-symbols-outlined u-ta-r">cached</span></p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="horse_auction">
    <ul class="horse_list">
      <template v-if="items && items.length > 0">
        <ItemRow
          v-for="item in items"
          :item="item"
          :parent="$data"
          :grandParent="parent"
          :greatGrandParent="grandParent"
          :showErrorsDialog="showErrorsDialog"
          :updateViewAfterBid="updateViewAfterBid"
          :errorMessages="[]"
          :key="item.exhibition_item_no"
          @cancelBidDialog="openCancelBidDialog"
          @bidConfirm="openBidConfirmDialog"
          :favorite="favorite"
          :getCategory="getCategory"
          :getColor="getColor"
          :getGender="getGender"
          :getPriceIncludeTax="getPriceIncludeTax"
          :updateFavoriteChangedItems="updateFavoriteChangedItems"
        />
      </template>
      <template v-else>
        <p class="u-ta-c u-mt10">{{$t('ITEM_SEARCH_NOT_FOUND_TEXT')}}</p>
      </template>
    </ul>
    <div v-if="isMoreLimit" class="u-ta-c u-mt30">
      <a
        class="btn-more"
        @click="searchItems(items.map(item => item.exhibition_item_no))"
      >
        {{$t('MY_PAGE_SHOW_MORE_BUTTON_LABEL')}}
      </a>
    </div>
  </div>

  <v-dialog v-model="errorsDialog" persistent content-class="error-dialog" style="z-index=5">
    <v-card>
      <v-divider></v-divider>
      <v-card-title>
        <div>入札エラー</div>
      </v-card-title>
      <v-card-text class="pt-3 overflow-y-auto">
        <v-row>
          <v-col v-for="(msg, index) in errors" :key="index"  cols="12" class="py-0 error--text subtitle-1">{{ msg }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="indigo white--text" @click="errorsDialog=false">{{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <bid-confirm-dialog
    :parent="$data"
    :confirmBidDialog="confirmBidDialog"
    :bidConfirm="bidConfirm"
    :closeBidConfirmDialog="closeBidConfirmDialog"
    :getPriceIncludeTax="getPriceIncludeTax"
    :bidConfirmItems="bidConfirmItems"
    :bidConfirmDialogMode="bidConfirmDialogMode"
    :bidConfirmSuccess="bidConfirmSuccess"
    :errors="bidConfirmMessages"
    :bidConfirmSuccessMessage="bidConfirmSuccessMessage"
  />
</div>
</template>
<script>
import Methods from '@/api/methods'
import Base from '@/common/base'
import BidConfirmDialog from './BidConfirmDialog'
import Filter from './Filter.js'
import ItemRow from './ItemRow'
import WebSocket from './WebSocket.js'
import BidConfirm from './bidConfirm.js'
export default {
  components : {
    ItemRow,
    BidConfirmDialog
  },
  props : {
    grandParent : {
      type    : Object,
      default : Object
    },
    parent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Function,
      default : Object
    },
    printItemsCsv : {
      type    : Function,
      default : Object
    },
    favorite : {
      type    : Function,
      default : Object
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    },
    updateFavoriteChangedItems : {
      type    : Function,
      default : Object
    }
  },
  mixins : [Filter, WebSocket, BidConfirm],
  data() {
    return {
      searchMenuOpen        : true,
      errorsDialog          : false,
      bulkBiddingDialog     : false,
      bulkItems             : [],
      bulkMessages          : [],
      showBulkBiddingButton : false,
      selectYears           : Array.from({length : 31}, (value, index) => `${index}歳`),
      selectPrice           : Array.from({length : 200}, (value, index) => 100 * (index + 1))
    }
  },
  mounted() {
    // 検索条件は画面を開いた時点で開いておく
    this.searchMenuOpen = true
  },
  methods : {
    bidBulk() {
      const items = this.bulkItems.map(item => {
        return {
          exhibitionItemNo : item.exhibition_item_no,
          bidPrice         : Base.localeString2Number(item.inputBidPrice)
        }
      })
      this.bidItems(items).then(data => {
        this.bulkMessages = data.bidList.filter(bid => bid.errorMessage)
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    categoryExtensionChange() {
      for (let index = 0;index < this.filter.category.length;index++) {
        this.filter.category[index] = this.filter.category[index] || this.filter.categoryExtension[index].filter(element => element).length > 0
      }
      this.filter = Object.assign({}, this.filter)
    },
    categoryChange(index) {
      if (!this.filter.category[index]) {
        this.filter.categoryExtension[index] = []
      }
      this.filter = Object.assign({}, this.filter)
    },
    updateFilter() {
      this.filter = Object.assign({}, this.filter)
    },
    showErrorsDialog(errors) {
      this.errors = errors
      this.errorsDialog = true
    },
    removeBulkItemByItemNo(itemNo) {
      this.bulkItems = this.bulkItems.filter(item => item.exhibition_item_no !== itemNo)
      if (this.bulkItems.length === 0) {
        this.bulkBiddingDialog = false
      }
    },
    sortClick(sortColumn) {
      const allSortColumns = [
        'currentPriceSort',
        'endDatetimeSort',
        'ageSort',
        'weightSort',
        'totalPrizeMoneySort'
      ]
      /*
       * 初回クリック時の挙動:
       * 現在価格はDESC
       * 終了時刻はASC
       * 馬齢はASC
       * 体重はDESC
       * 総賞金はDESC
       */
      switch (sortColumn) {
      case 'currentPriceSort':
        this.filter[sortColumn] = (this.filter[sortColumn] === 'desc' ? 'asc' : 'desc')
        break
      case 'endDatetimeSort':
        this.filter[sortColumn] = (this.filter[sortColumn] === 'asc' ? 'desc' : 'asc')
        break
      case 'ageSort':
        this.filter[sortColumn] = (this.filter[sortColumn] === 'asc' ? 'desc' : 'asc')
        break
      case 'weightSort':
        this.filter[sortColumn] = (this.filter[sortColumn] === 'desc' ? 'asc' : 'desc')
        break
      case 'totalPrizeMoneySort':
        this.filter[sortColumn] = (this.filter[sortColumn] === 'desc' ? 'asc' : 'desc')
        break
      default:
        break
      }

      // Reset other sort columns
      allSortColumns.map(x => {
        if (x !== sortColumn) {
          this.filter[x] = null
        }
      })
      this.updateFilter()
      this.searchItems()
    },
    resetSearchItems() {
      this.filter.southkanto_moveable = null
      this.filter.bad_habit = null
      this.filter.injury_history = null
      this.filter.timeover = null
      this.filter.favorite = null
      this.updateFilter()
      this.searchItems()
    },
    reloadPriceItems() {
      this.filter.initLimit = this.filter.limit
      this.searchItems(null)

      /*
       * Reload list when btn reload Price clicked
       * if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN) !== null) {
       */
      this.$nextTick(() => {
        this.updateFavoriteChangedItems(null)
        this.$nextTick(() => {
          this.updateFavoriteChangedItems('reload')
        })
      })
      // }
    },
    searchMenuCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('menu-btn').checked === false) {
        console.log('uncheck')
        this.searchMenuOpen = false
      } else {
        console.log('checked')
        this.searchMenuOpen = true
      }
    }
  },
  watch : {
    '$i18n.locale'() {
      this.searchItems()
      this.getItemSearchConstants()
    }
  }
}
</script>

<style scoped lang="css">
  @media screen and (min-width: 769px) {
    .row {
      /* margin: 0 -15px !important; */
      margin-top: 0px !important;
      margin-right: inherit !important;
      margin-bottom: 0px !important;
      margin-left: inherit !important;
    }
  }
  .v-application p {
    margin-bottom: inherit !important;
  }
  @media screen and (max-width: 768px) {
    .search_acodion >>> p {
      margin-bottom: inherit !important;
    }
    .search_auction .search_box .row {
      margin-top: 0 !important;
      margin-bottom: 2px !important;
    }
  }
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  #auction .content_wrap .search_auction .search_box .sort_list .sort_list_tab,
  #auction .content_wrap .search_auction .search_box .sort_list .sort_list_tab a {
    text-decoration: none !important;
    cursor: pointer;
  }
  .sort_list_reload {
    cursor: pointer;
  }
  .sort_list .sort_list_tab .active {
    color: #1976d2;
  }
  .sort_list .sort_list_tab a {
    color: #333;
  }
</style>
<style scoped>
  .u-bg-blue {
    background-color: #0862A5 !important;
  }
  .u-bg-red {
    background-color: #f00 !important;
  }
  .filter_list_reset {
    background-color: #0862A5;
    border-radius: 2em;
    margin-left: auto;
    margin-bottom: 10px;
    padding: 0 0.5rem;
    box-shadow: 0 1px 0 #999;
  }
  .filter_list_reset a {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  .btn-more {
    font-size: 2rem;
    letter-spacing: 4px;
    margin: 60px auto 0;
    width: 100%;
    max-width: 360px;
    background: #0862A5;
    color: #fff;
    padding: 5px 30px;
    text-decoration: none;
    cursor: pointer;
  }
  .sort_label {
    border: none !important;
    background: none !important;
    text-align: left !important;
  }
  .sort_list {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .sort_list .sort_list_tab {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .sort_list_reload {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .sort_list_reload .price_reload p {
    vertical-align: middle;
  }
  @media screen and (max-width: 768px) {
    .sort_list .sort_list_tab {
      width: auto !important;
      min-width: 110px !important;
      margin-right: 5px !important;
      margin-left: 5px !important;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
