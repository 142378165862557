<template>
<div class="slider_wrap">
    <div v-if="images" class="slider-container">
        <Slick ref="slick" :options="slickOptions" id="slider">
            <li v-for="(image, index) in (images)" :key="index">
                <a @click.stop="openLightBox(index)" class="img_box">
                  <img
                    :key="`img${image.file_path}`"
                    :data-lazy="(image.postar_file_path || image.file_path) | getFullImagePath"
                    alt=""
                  >
                  <img
                    v-if="image.postar_file_path" class="img_video"
                    src="@/assets/images/common/play_btn4.svg"
                    alt=""
                  />
                </a>
            </li>
        </Slick>
        <Slick ref="slickNav" :options="slickNavOptions" id="thumbs">
            <li v-for="(image, index) in images" :key="index">
              <a class="img_box">
                <img
                  v-if="image.postar_file_path"
                  :key="`navvideo_${image.file_path}`"
                  :data-lazy="image.postar_file_path | getFullImagePath"
                  alt=""
                >
                <img
                  v-else
                  :key="`navimg_${image.file_path}`"
                  :data-lazy="image.file_path | getFullImagePath"
                  alt=""
                >
                <img
                  v-if="image.postar_file_path" class="img_video"
                  src="@/assets/images/common/play_btn4.svg"
                  alt=""
                />
              </a>
            </li>
        </Slick>
    </div>
    <div v-else class="slider-container">
        <Slick ref="slick" :options="slickOptions" id="slider">
            <li><img src="@/assets/images/no_photo.jpg"/></li>
        </Slick>
        <Slick ref="slickNav" :options="slickNavOptions" id="thumbs">
            <!-- <li><img src="@/assets/images/no_photo.jpg"/></li> -->
        </Slick>
    </div>
    <template v-if="images">
      <VueImageLightbox
        ref="lightbox"
        :show="showLightbox"
        @close="closeLightBox"
        :images="images"
        :exhibitionItemNo="exhibitionItemNo"
        @change="changeHighlight"
        :showCaption="false"
      />
    </template>
</div>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import Slick from 'vue-slick'
import VueImageLightbox from './VueImageLightbox'

export default {
  name       : 'Gallery',
  components : {
    Slick,
    VueImageLightbox,
  },
  props : {
    images : {
      type    : Array,
      default : Object
    },
    exhibitionItemNo : {
      type    : String,
      default : null
    }
  },
  mixins : [CommonFilters],
  data() {
    return {
      showLightbox      : false,
      currentIndexImage : 0,

      isSliderVisible : false,
      slickOptions    : {
        slidesToShow : 1,
        asNavFor     : '#thumbs',
        arrows       : true
      },
      slickNavOptions : {
        slidesToShow  : 5,
        asNavFor      : '#slider',
        arrows        : true,
        focusOnSelect : true,
        responsive    : [
          {
            breakpoint : 767,
            settings   : {
              slidesToShow : 4,
            }
          }
        ]
      }
    }
  },
  methods : {
    videoPlaying(event) {
      console.log(event.type)
      if (event.target.ended) {
        console.log('ended -> re-load')
        event.target.load()
        event.target.play()
      }
    },
    changeHighlight(currentIndex) {
      console.log('changeHighlight: ', currentIndex)
      this.currentIndexImage = currentIndex
      if (this.$refs.slick) {
        this.$nextTick(() => {
          this.$refs.slick.goTo(this.currentIndexImage)
        })
      }
    },
    openLightBox(index) {
      console.log('openLightBox: ', index)
      // This.$root.$el.classList.add("overflow-y-hidden")
      document.documentElement.classList.add('overflow-y-hidden')
      this.showLightbox = true
      this.$refs.lightbox.showImage(index)
    },
    closeLightBox() {
      console.log('closeLightBox')
      // This.$root.$el.classList.remove("overflow-y-hidden")
      document.documentElement.classList.remove('overflow-y-hidden')
      this.showLightbox = false
    }
  },
  created() {
    console.log('created')
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
      this.$refs.slickNav.destroy()
    }
    if (this.$refs.slick && !this.$refs.slick.$el.classList.contains('slick-initialized')) {
      this.$nextTick(() => {
        this.$refs.slick.create(this.slickOptions)
        this.$refs.slickNav.create(this.slickNavOptions)
      })
    }
  },
  updated() {
    console.log('updated')
    if (this.$refs.slick) {
      this.$refs.slick.goTo(this.currentIndexImage)
    }
  },
  watch : {
    exhibitionItemNo(val) {
      console.log('exhibitionItemNo changed', val)
      // Reset currentIndexImage
      this.currentIndexImage = 0
    },
    images() {
      console.log('images changed')
      if (this.$refs.slick && this.$refs.slickNav) {
        this.$refs.slick.destroy()
        this.$refs.slickNav.destroy()
      }
      if (this.$refs.slick && !this.$refs.slick.$el.classList.contains('slick-initialized')) {
        this.$nextTick(() => {
          this.$refs.slick.create(this.slickOptions)
          this.$refs.slickNav.create(this.slickNavOptions)
          if (this.currentIndexImage !== null) {
            this.$refs.slick.goTo(this.currentIndexImage)
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .v-application p {
    margin-bottom: initial !important;
  }
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  #slider img {
    cursor: pointer;
  }
  #slider .slick-slide .img_box {
    position: relative;
  }
  #slider .slick-slide .img_video {
    height: auto;
    width: 100px;
    position: absolute;
    top: 10px;
    left: 10px;
    // transform: translate(-50%, -50%);
    cursor: pointer;
    pointer-events: none ;
  }
  #thumbs .slick-slide .img_box {
    position: relative;
  }
  #thumbs .slick-slide .img_video {
    height: auto;
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    pointer-events: none ;
  }
</style>
<style scoped src="@/assets/css/slick.css"></style>
