import {w3cwebsocket} from 'websocket'
const W3CWebSocket = w3cwebsocket

export default {
  data() {
    return {
      ws : null,
    }
  },
  methods : {
    updateChangedItems(changedItems) {
      changedItems.map(changed => {
        const item = this.items.find(item => String(item.exhibition_item_no) === String(changed.exhibition_item_no))
        if (item) {
          item.bid_status = Object.assign({}, item.bid_status, changed.bid_status)
          item.attention_info = Object.assign({}, item.attention_info, changed.attention_info)
          // Bid history
          if (this.item && String(this.item.exhibition_item_no) === String(changed.exhibition_item_no)
            && this.$route.name === this.$define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME) {
            this.bid_histories = changed.bid_histories ? changed.bid_histories.map(x => x) : null
          }
        }
      })
    },
    connectWs(token) {
      this.ws = new W3CWebSocket(process.env.VUE_APP_WEB_SOCKET_ENDPOINT + token)
      const self = this
      this.ws.onclose = function(event) {
        console.log(event)
        console.log('The connection has been closed successfully.')
        if (event.reason) {
          console.log('reconnect to wss')
          self.connectWs(token)
        }
      }
      this.ws.onmessage = e => {
        if (typeof e.data === 'string') {
          console.log(e.data)
          const res = JSON.parse(e.data)
          if (res.name === 'changed-items') {
            this.updateChangedItems(res.items)
          }
        }
      }
    }
  },
  created() {
    if (this.$route.meta.webSocketOn) {
      const token = this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
      /*
       * 非ログインの場合にもSocketを動く
       * if (token) {
       */
      this.connectWs(token)
      // }
    }
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close()
    }
  }
}
