<template>
  <v-dialog v-model="withdrawMemberComfirmDialog" persistent style="z-index=1001;" width="460px">
    <v-card style="border-radius: 1rem;">
      <v-card-text class="pt-3 overflow-y-auto">
        <v-container style="padding-top: 0; padding-bottom: 0;">
          <form>
            <div>
              <div class="modal_body" id="check-confirm" aria-labelledby="ModalLabel">
                <div class="modal_header u-ta-c">
                  <h5 class="modal_title" id="ModalLabel">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT")}}</h5>
                  <button
                   type="button"
                   class="modal_close"
                   data-dismiss="modal"
                   :aria-label="$t('ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL')"
                   @click.stop="closeWithdrawDialog"
                  ><span class="material-symbols-outlined">close</span></button>
                </div>
                <div class="modal_content" v-if="errors===null || errors.length === 0">
                  <p class="u-pb30">
                    <label class="login-required-txt">
                        {{$t('CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT')}}
                    </label>
                  </p>
                  <div class="modal_btn" style="display: flex; justify-content: space-between;">
                    <button
                      type="button"
                      id="login_btn"
                      class="btn modal_comfirm_btn"
                      @click="withdrawMember"
                    >
                      {{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL")}}
                    </button>
                    <button
                      type="button"
                      id="close_btn"
                      class="btn modal_comfirm_btn"
                      @click="closeWithdrawDialog"
                    >
                      {{$t('CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL')}}
                    </button>
                  </div>
                </div>
                <div class="modal_content" v-else>
                  <p class="u-pb30">
                      <label v-for="(err, index) in errors" :key="index" :class="err.success ? '' : 'txt-err'">{{err.errorMessage}}</label>
                  </p>
                  <div class="modal_btn">
                    <button
                      type="button"
                      id="close_btn"
                      class="btn modal_comfirm_btn"
                      @click="closeWithdrawDialog"
                    >
                      {{$t('CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    withdrawMemberComfirmDialog : {
      type    : Boolean,
      default : false
    },
    withdrawMember : {
      type    : Function,
      default : Object
    },
    closeWithdrawDialog : {
      type    : Function,
      default : Object
    },
    errors : {
      type    : Array,
      default : null
    },
    withdrawSuccess : {
      type    : Boolean,
      default : false
    },
  },
  mounted() {
  },
  methods : {
  }
}
</script>
<style scoped lang="scss">
    .v-application p {
        margin-bottom: inherit !important;
    }
    .modal_body {
        border: none !important;
    }
    .v-dialog__content {
        justify-content: center;
        align-items: center;
    }
    .v-dialog > .v-card > .v-card__text {
        padding: 0 !important;
    }
    .v-card > .v-card__text {
        color: #333 !important;
    }
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .modal_comfirm_btn:disabled {
        background-color: #94A5B2;
        color: #fff;
        cursor: not-allowed;
    }
    .txt-err {
      color: #f00;
      cursor: text;
    }
    .login-required-txt {
      color: #f00;
      cursor: text;
    }
    .modal_comfirm_btn:not(disabled){
      color: #fff;
      background-color: #0862A5;
    }
    .no_underline {
      cursor: pointer !important;
      text-decoration: none !important;
    }
    @media screen and (min-width: 769px) {
      .success_message_small {
        font-size: 1.2em !important;
      }
    }
    .modal_comfirm_btn {
      margin-left: 10px !important;
      margin-right: 10px !important;
      text-align: center;
    }
</style>

<style scoped src="@/assets/css/top.css"></style>
