import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=670a2ac0&scoped=true"
import script from "./Register.vue?vue&type=script&lang=js"
export * from "./Register.vue?vue&type=script&lang=js"
import style0 from "./Register.vue?vue&type=style&index=0&id=670a2ac0&prod&scoped=true&lang=css"
import style1 from "./Register.vue?vue&type=style&index=1&id=670a2ac0&prod&scoped=true&lang=scss"
import style2 from "./Register.vue?vue&type=style&index=2&id=670a2ac0&prod&lang=scss"
import style3 from "./Register.vue?vue&type=style&index=3&id=670a2ac0&prod&scoped=true&lang=css"
import style4 from "@/assets/css/contents02.css?vue&type=style&index=4&id=670a2ac0&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670a2ac0",
  null
  
)

export default component.exports