<template>
  <v-dialog v-model="confirmBidDialog" persistent style="z-index=1001;" width="460px">
    <v-card style="border-radius: 1rem;">
      <v-card-text class="pt-3 overflow-y-auto">
        <v-container style="padding-top: 0; padding-bottom: 0;">
          <form>
            <div v-show="bidConfirmDialogMode === 1">
              <div class="modal_body" id="check-confirm" aria-labelledby="ModalLabel" v-if="!bidConfirmSuccess">
                <div class="modal_header u-ta-c">
                  <h5 class="modal_title" id="ModalLabel">{{$t("ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER")}}</h5>
                  <button
                   type="button"
                   class="modal_close"
                   data-dismiss="modal"
                   aria-label="閉じる"
                   @click.stop="closeBidConfirmDialog"
                  ><span class="material-symbols-outlined">close</span></button>
                </div>
                <div class="modal_content u-pt5 u-pb5" v-for="(item, index) in bidConfirmItems" :key="index">
                  <p class="modal_name u-pb5">
                    {{item.free_field.nickname}}
                  </p>
                  <div class="modal_price">
                    <div class="d-flex u-ph5" style="line-height:1">
                      <p class="modal_price_text">{{$t("ITEM_ROW_BID_PRICE_LABEL")}}：</p>
                      <p class="modal_price_input" id="modalPrice"><span class="input_price">{{item && item.inputBidPrice ? item.inputBidPrice : ''}}</span>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</p>
                    </div>
                    <p class="u-ta-r">（{{$t('ITEM_ROW_PRICE_WITH_TAX_LABEL')}} <span class="">{{item && item.inputBidPrice ? getPriceIncludeTax(item.inputBidPrice) : ''}}</span>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}）</p>
                  </div>
                  <p class="u-pv15" v-if="errors===null || errors.length === 0">
                      <label for="comfirm_check" v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!==null">
                          <input type="checkbox" name="comfirm_check" id="comfirm_check" @click="priceCheckedClick" >
                          {{$t('ITEM_SEARCH_CONFIRM_BIDDING_CONFIRMED_LABEL')}}
                      </label>
                      <label class="login-required-txt" v-else>
                          {{$t('ITEM_SEARCH_CONFIRM_BIDDING_LOGIN_REQUIRED_LABEL')}}
                      </label>
                  </p>
                  <p class="u-pv15" v-else>
                      <label class="txt-err" v-for="(err, index) in errors" :key="index">
                          {{err.errorMessage}}
                      </label>
                  </p>
                  <div class="modal_btn" v-if="errors===null || errors.length === 0">
                    <button
                      v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!==null"
                      type="button"
                      id="bid_btn"
                      class="btn modal_comfirm_btn"
                      @click="bidConfirm"
                      disabled="disabled"
                    >
                      {{$t("ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL")}}
                    </button>
                    <button
                      v-else
                      type="button"
                      id="login_btn"
                      class="btn modal_comfirm_btn"
                      @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: $route.params.id, filter: parent.filter})"
                    >
                      {{$t("ITEM_SEARCH_BIDDING_MODAL_LOGIN_BUTTON_LABEL")}}
                    </button>
                  </div>
                  <div class="modal_btn" v-else>
                    <button
                      type="button"
                      id="close_btn"
                      class="btn modal_comfirm_btn"
                      @click="closeBidConfirmDialog"
                    >
                      {{$t('ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL')}}
                    </button>
                  </div>
                  <p class="u-fs10 u-mt10">
                    ※上記ボタンをクリックすると<a class="no_underline" @click="downloadFile(businessProcessPdf)">業務規程</a>に同意したものとみなします。
                  </p>
                </div>
              </div>

              <div class="modal_body" id="check-confirm" aria-labelledby="ModalLabel" v-else>
                <div class="modal_content">
                  <p
                    class="modal_name"
                    v-bind:class="{'success_message_small': bidConfirmSuccessMessage !== $t('ITEM_ROW_BID_SUCCESS_MESSAGE')}"
                    v-html="bidConfirmSuccessMessage"
                  ></p>
                  <p class="u-pv15">
                      <label></label>
                  </p>
                  <div class="modal_btn">
                    <button
                      type="button"
                      id="close_btn"
                      class="btn modal_comfirm_btn"
                      @click="closeBidConfirmDialog"
                    >
                      {{$t('ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="bidConfirmDialogMode !== 1">
              <div class="modal_body" id="check-confirm" aria-labelledby="ModalLabel">
                <div class="modal_header u-ta-c">
                  <h5 class="modal_title" id="ModalLabel">{{$t("ITEM_SEARCH_CONFIRM_BIDDING_CANCEL_MODAL_HEADER")}}</h5>
                  <button
                   type="button"
                   class="modal_close"
                   data-dismiss="modal"
                   :aria-label="$t('ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL')"
                   @click.stop="closeBidConfirmDialog"
                  ><span class="material-symbols-outlined">close</span></button>
                </div>
                <div class="modal_content" v-if="errors===null || errors.length === 0">
                  <p class="u-pb30">
                      <label class="login-required-txt">
                          {{$t('ITEM_SEARCH_CONFIRM_BIDDING_LOGIN_REQUIRED_LABEL')}}
                      </label>
                  </p>
                  <div class="modal_btn">
                    <button
                      type="button"
                      id="login_btn"
                      class="btn modal_comfirm_btn"
                      @click="$routerGo($define.PAGE_NAMES.LOGIN_PAGE_NAME, null, {next: $route.name, id: $route.params.id, filter: parent.filter})"
                    >
                      {{$t("ITEM_SEARCH_BIDDING_MODAL_LOGIN_BUTTON_LABEL")}}
                    </button>
                  </div>
                </div>
                <div class="modal_content" v-else>
                  <p class="u-pb30">
                      <label v-for="(err, index) in errors" :key="index" :class="err.success ? '' : 'txt-err'">{{err.errorMessage}}</label>
                  </p>
                  <div class="modal_btn">
                    <button
                      type="button"
                      id="close_btn"
                      class="btn modal_comfirm_btn"
                      @click="closeBidConfirmDialog"
                    >
                      {{$t('ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadFile from '@/common/downloadFile';
export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    confirmBidDialog : {
      type    : Boolean,
      default : false
    },
    bidConfirm : {
      type    : Function,
      default : Object
    },
    closeBidConfirmDialog : {
      type    : Function,
      default : Object
    },
    getPriceIncludeTax : {
      type    : Function,
      default : Object
    },
    bidConfirmItems : {
      type    : Array,
      default : () => []
    },
    errors : {
      type    : Array,
      default : null
    },
    bidConfirmSuccess : {
      type    : Boolean,
      default : false
    },
    bidConfirmDialogMode : {
      type    : Number,
      default : 1
    },
    bidConfirmSuccessMessage : {
      type    : String,
      default : ''
    },
  },
  mixins : [DownloadFile],
  mounted() {
  },
  methods : {
    priceCheckedClick() {
      // ボタンの活性・非活性
      if (document.getElementById('comfirm_check').checked === false) {
        console.log('uncheck')
        document.getElementById('bid_btn').disabled = true
      } else {
        console.log('checked')
        document.getElementById('bid_btn').disabled = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
    .v-application p {
        margin-bottom: inherit !important;
    }
    .modal_body {
        border: none !important;
    }
    .v-dialog__content {
        justify-content: center;
        align-items: center;
    }
    .v-dialog > .v-card > .v-card__text {
        padding: 0 !important;
    }
    .v-card > .v-card__text {
        color: #333 !important;
    }
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .modal_comfirm_btn:disabled {
        background-color: #94A5B2;
        color: #fff;
        cursor: not-allowed;
    }
    .txt-err {
      color: #f00;
      cursor: text;
    }
    .login-required-txt {
      color: #f00;
      cursor: text;
    }
    .modal_comfirm_btn:not(disabled){
      color: #fff;
      background-color: #0862A5;
    }
    .no_underline {
      cursor: pointer !important;
      text-decoration: none !important;
    }
    @media screen and (min-width: 769px) {
      .success_message_small {
        font-size: 1.2em !important;
      }
    }
</style>

<style scoped src="@/assets/css/top.css"></style>
