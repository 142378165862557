/**
 * 日本語
 */
export default {
  SITE_TITLE                              : 'サタデーオークション',
  NAME                                    : '日本語',
  LANGUAGE_CODE                           : 'jp',
  SIDE_NAME                               : '公式サラブレッドオークションサイト',
  SIDE_LOGO_TITLE                         : 'エクモ',
  PAGE_TOP                                : 'TOP',

  HEADER_BIDDING_BUTTON_LABEL             : '入札中',
  HEADER_FAVORITE_LIST_BUTTON_LABEL       : 'お気に入りリスト',
  HEADER_LOGOUT_BUTTON_LABEL              : 'ログアウト',
  PAGE_MEMBER_REGISTRATION                : '会員登録',
  PAGE_MEMBER_REGISTRATION_NAME           : '会員登録申請',
  PAGE_LOGIN                              : 'ログイン',
  PAGE_NEW_MEMBER_REGISTRATION            : '新規会員登録',
  PAGE_MY_PAGE                            : 'マイページ',
  PAGE_BEGINNER_GUIDE                     : 'はじめての方へ',
  LINK_GUIDANCE_FAQ                       : 'Q&A',
  LINK_PROFILE_GUILD                      : '会社案内',
  LINK_PROFILE                            : '会社概要',
  LINK_PRIVACY                            : '個人情報保護方針',
  LINK_LOW_COMMERCE                       : '特定商取引法に<span class="d-inline-block">基づく表記</span>',
  PAGE_TOP_NAME                           : 'トップ',
  PAGE_INTRO_NAME                         : '参加される方へ',
  PAGE_MY_PAGE_HISTORY                    : '過去の落札馬',
  PAGE_MY_PAGE_MEMBER_INFO                : '会員情報の確認と変更',

  LOGIN_PAGE_TOP_TITLE                          : 'サタデーオークション ログイン',
  LABEL_LOGIN_EMAIL                             : 'メールアドレス',
  PLACEHOLDER_USERID                            : '',
  LABEL_PASSWORD                                : 'パスワード',
  PLACEHOLDER_PASSWORD                          : '',
  LABEL_USERNAME                                : 'ニックネーム',
  PLACEHOLDER_USERNAME                          : '',
  PS_USERNAME_BEFORE                            : 'ログインする担当者さまのニックネームをご記入ください。',
  PS_USERNAME_AFTER                             : 'ログイン後、担当者別で履歴確認することができます。',
  LABEL_SAVE_ID_PASSWORD_USERNAME               : 'メールアドレス・パスワードを保存する',
  LINK_FORGOT_PASSWORD                          : 'パスワードを忘れた方はコチラ',
  LINK_FORGOT_PASSWORD_NAME                     : 'パスワードをお忘れの方',
  LABEL_PARTICIPATION_TERMS                     : '参加規約',
  LABEL_AGREE_TERMS_PARTICIPATION               : '参加規約に同意する',
  LINK_NEW_MEMBER_REGISTRATION_APPLICATION_FREE : '新規会員登録申請(無料)',
  PS_MEMBERSHIP_REGISTRATION_REQUIRED           : '※馬の価格を見るには会員登録が必要です。',
  OTHER_LANGUAGE                                : 'English',
  OTHER_LANGUAGE_SHOT                           : 'EN',
  OTHER_LANGUAGE_CODE                           : 'en',
  DATE_FORMAT                                   : 'YYYY/MM/DD(dd)',
  DATE_TIME_FORMAT                              : 'YYYY/MM/DD HH:mm',
  ROW_BID_DATE_TIME_FORMAT                      : 'MM/DD HH:mm',
  ROW_BID_DATE_FORMAT                           : 'YYYY年M月D日',
  ROW_BID_TIME_FORMAT                           : 'H:mm',

  TEXT_FORMAT       : '^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$',
  TEXT_FORMAT_ERROR : '半角英数字・全角文字・記号で入力してください。',

  CURRENT_PASSWORD_ERROR_MESSAGE : 'パスワードの変更時は、現在のパスワードを入力してください。',
  PASSWORD_CONFIRM_ERROR_MESSAGE : 'パスワードとパスワード(確認用)が一致していません。',
  MAIL_CONFIRM_ERROR_MESSAGE     : 'メールアドレスとメールアドレス(確認用)が一致していません。',
  MEMBER_INFO_EMPTY_MESSAGE      : '入力してください。',

  NO_RECOMMEND_ITEM_IN_SESSION_MESSAGE  : '現在{names}を開催中です',
  NO_RECOMMEND_ITEM_SCHEDULED_MESSAGE   : '次回のオークション公開は、<br>{start_date}～の予定です<br>どうぞ楽しみにお待ちください',
  NO_RECOMMEND_ITEM_NO_SCHEDULE_MESSAGE : '次回のオークション公開をどうぞお楽しみにお待ちください',

  TOP_PAGE_AUCTION_RECOMMEND_ITEMS_VIEW_HEADER            : 'WEB オークション出展',
  TOP_PAGE_AUCTION_NEW_ITEMS_VIEW_HEADER                  : '在庫　新着',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_PREFIX_LABEL : 'オークション',
  TOP_PAGE_LIST_OF_AUCTION_EXHIBITORS_BUTTON_SUBFIX_LABEL : 'オークション一覧',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_PREFIX_LABEL  : 'テンダー',
  TOP_PAGE_LIST_OF_TENDER_EXHIBITORS_BUTTON_SUBFIX_LABEL  : 'テンダー一覧',
  TOP_PAGE_CRANE_LIST_BUTTON_PREFIX_LABEL                 : 'クレーン',
  TOP_PAGE_HORSE_LIST_BUTTON_PREFIX_LABEL                 : '馬',
  TOP_PAGE_CRANE_LIST_BUTTON_SUBFIX_LABEL                 : '一覧',
  TOP_PAGE_AUCTION_NEW_ITEMS_BUTTON_LABEL                 : '一覧',
  TOP_PAGE_HORSE_LIST_BUTTON_SUBFIX_LABEL                 : '一覧',
  TOP_PAGE_INVENTORY_MACHINE_LIST_BUTTON_LABEL            : '在庫一覧',
  TOP_PAGE_LIST_OF_CRANE_STOCK_MACHINES_BUTTON_LABEL      : 'クレーン在庫一覧',
  TOP_PAGE_AUCTION_SCHEDULE_VIEW_HEADER                   : '入札会スケジュール',
  TOP_PAGE_AUCTION_STATUS_AUCTION_IN_SESSION_LABEL        : '開催中',
  TOP_PAGE_AUCTION_STATUS_AUCTION_WILL_BE_HELD_LABEL      : '開催予定',
  TOP_PAGE_AUCTION_STATUS_AUCTION_ENDED_LABEL             : '開催終了',
  TOP_PAGE_ITEM_MODEL_LABEL                               : 'モデル',
  TOP_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                 : '号機',
  TOP_PAGE_ITEM_MODEL_YEAR_LABEL                          : '年式',
  TOP_PAGE_NOTICES_VIEW_HEADER                            : 'お知らせ',
  TOP_PAGE_FOR_NEWBIE_LABEL                               : 'はじめての方へ',
  TOP_PAGE_AUCTION_GUIDE_LINK_LABEL                       : 'WEB オークション/テンダー/商談の流れ',
  TOP_PAGE_PRICE_CURRENCY_MARK                            : '￥',

  TOP_PAGE_ITEM_NICKNAME_LABEL                            : '馬名',
  TOP_PAGE_ITEM_AGE_LABEL                                 : '年齢',
  TOP_PAGE_ITEM_GENDER_LABEL                              : '性別',
  TOP_PAGE_ITEM_WEIGHT_LABEL                              : '体重',
  TOP_PAGE_AGE_LABEL                                      : '歳',

  LINEUP_ITEMS_DATE_TIME_FORMAT                           : 'YYYY年M月D日（ddd）H:mm',
  LINEUP_ITEMS_GUILD_TEXT                                 : '出品馬名をクリックすると各馬の基本情報が、総賞金をクリックすると競走成績がご覧いただけます。',
  LINEUP_ITEMS_GUILD_TEXT1                                : '馬名・性別・馬齢・総賞金・南関東転入可否・馬体重順での並べ替えが可能です。それぞれのタイトルを押すとその順で上場馬が並び替わります。 ',
  LINEUP_ITEMS_GUILD_TEXT2                                : '入札をされる前に必ず ',
  LINEUP_ITEMS_GUILD_TEXT3                                : ' サタデーオークション業務規程(PDF)',
  LINEUP_ITEMS_GUILD_TEXT4                                : ' をご確認ください。',
  LINEUP_ITEMS_AUCTION_AUTO_EXTENDED_LABEL                : '（自動延長あり）',
  LINEUP_ITEMS_AUCTION_END_LABEL                          : '終了',
  LINEUP_ITEMS_NAME_LABEL                                 : '出品馬名',
  LINEUP_ITEMS_GENDER_LABEL                               : '性別',
  LINEUP_ITEMS_GENDER_LABEL_SP                            : '性\n別',
  LINEUP_ITEMS_AGE_LABEL                                  : '馬齢',
  LINEUP_ITEMS_AGE_LABEL_SP                               : '馬\n齢',
  LINEUP_ITEMS_REGISTER_SELLER_LABEL                      : '販売申込者',
  LINEUP_ITEMS_TOTAL_PRIZE_MONEY_LABEL                    : '総賞金',
  LINEUP_ITEMS_SOUTHKANTO_MOVEABLE_LABEL                  : '南関東\n転入',
  LINEUP_ITEMS_WEIGHT_LABEL                               : '馬体重',
  LINEUP_ITEMS_STALLION_LABEL                             : '受胎種牡馬名',
  LINEUP_ITEMS_DETAIL_BTN_LABEL                           : '詳細',
  LINEUP_ITEMS_KAISAI_LABEL                               : '開催',
  LINEUP_ITEMS_AGE_0_LABEL                                : '当',
  LINEUP_ITEMS_MISHUSSO_LABEL                             : '未出走',

  MEMBER_REQUEST_DOCUMENT_EXPLANATION_MESSAGE           : ' 会員ご登録申請にあたり当社にて審査を行っています。</br>以下からダウンロードいただき、必要書類をPDF（JPEG/PNGも可）にてご用意お願いします。',
  MEMBER_REQUEST_DOCUMENT_CAUTION_MESSAGE               : '※個人名義で会員登録される場合、2は会社名でなく個人名での留意事項への記載、3は免許証など個人の身分証明書のコピーをご提出下さい。',
  MEMBER_REQUEST_DOCUMENT_TITLE                         : '会員申請必要書類',
  MEMBER_REQUEST_DOWNLOAD_SAMPLE_DOCUMENT_BUTTON_LABEL  : '申請必要書類ダウンロード',
  MEMBER_REQUEST_FORM_CAUTION_TITLE                     : '【注意事項】日本国内のユーザー様へ',
  MEMBER_REQUEST_FORM_CAUTION_MESSAGE                   : '<span class="fontB">【注意事項】日本国内のユーザー様へ</span><br> 株式会社エクモでは、日本国内のユーザー様へ直接販売はしておりません。<br> ご購入をご希望の方は<a href="https://auction.keiba.rakuten.co.jp/" target="_blank">販売サービス窓口（エクモのオークションサイトへ移動します）</a>からお問い合わせください。',
  MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE           : '必要書類がご用意できましたら、以下のフォーム項目をご入力ください。<br><em class="req">必須</em>のついている項目は必ずご入力ください。',
  MEMBER_REQUEST_FORM_POST_CODE_LABEL                   : '郵便番号',
  MEMBER_REQUEST_FORM_ADDRESS_LABEL                     : '住所',
  MEMBER_REQUEST_FORM_REQUIRED_LABEL                    : '必須',
  MEMBER_REQUEST_FORM_TEL_LABEL                         : '携帯電話番号',
  MEMBER_REQUEST_FORM_TEL_LABEL2                        : '(もしくは必ず連絡の取れる電話番号)',
  MEMBER_REQUEST_FORM_FAX_LABEL                         : 'FAX番号',
  MEMBER_REQUEST_FORM_EMAIL_LABEL                       : 'メールアドレス',
  MEMBER_REQUEST_FORM_EMAIL_LABEL2                      : '(PDFファイルが受信できるアドレス)',
  MEMBER_REQUEST_FORM_CONFIRM_EMAIL_LABEL               : 'メールアドレス(確認用)',
  MEMBER_REQUEST_FORM_PASSWORD_LABEL                    : 'パスワード<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_CONFIRM_PASSWORD_LABEL            : 'パスワード(確認用)<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_PASSWORD_RULE                     : '※パスワードは8文字以上、14文字以内で英字、数字を含めて指定してください。',
  MEMBER_REQUEST_FORM_FILE_LABEL                        : '添付ファイル<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_LABEL                       : '個人情報の取り扱いについて<em class="req">必須</em>',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL              : '同意する',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1   : '必ず「',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2   : '個人情報保護方針',
  MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3   : '」をご確認ください。',
  MEMBER_REQUEST_FORM_FILE_SELECT_BUTTON_LABEL          : 'ファイルを選択',
  MEMBER_REQUEST_FORM_FILE_DELETE_BUTTON_LABEL          : '削除',
  MEMBER_REQUEST_FORM_FILE_UNSELECTED_MESSAGE           : 'ファイルが選択されていません',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_MESSAGE       : '<p class="kome">※PDF/JPEG/PNG:5MB以内</p><p class="kome">※ファイル名は半角英数字で設定の上、添付お願い致します。</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_NONJP         : '<p class="kome">※英語の名刺の添付をお願い致します。</p>',
  MEMBER_REQUEST_FORM_FILE_UPLOAD_CAUTION_HEADER        : '申請必要書類',
  MEMBER_REQUEST_FORM_SUBMIT_LABEL                      : '入力内容を確認する',
  MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL     : '検索',
  MEMBER_REQUEST_FORM_FULL_WIDTH_LABEL                  : '[全角]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_NUMBERS_LABEL          : '[半角数字]',
  MEMBER_REQUEST_FORM_FULL_WIDTH_ALPHANUMERICAL_LABEL   : '[半角英数字]',
  MEMBER_REQUEST_FORM_POST_CODE_PLACEHOLDER             : '',
  MEMBER_REQUEST_FORM_ADDRESS_PLACEHOLDER               : '',
  MEMBER_REQUEST_FORM_PREFECTURES_PLACEHOLDER           : '',
  MEMBER_REQUEST_FORM_MUNICIPALITIES_PLACEHOLDER        : '',
  MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER              : '',
  MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER          : '',
  MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER            : '',
  MEMBER_REQUEST_FORM_WITHDRAW_LABEL                    : '退会する',
  MEMBER_PAGE_CHANGE_TITLE                              : '会員情報の確認と変更',
  MEMBER_PAGE_CHANGE_UPDATE_BUTTON_LABEL                : '変更する',

  MEMBER_REQUEST_FORM_NICKNAME_LABEL                    : 'ニックネーム',
  MEMBER_REQUEST_FORM_NAME_LABEL                        : '氏名',
  MEMBER_REQUEST_FORM_FURIGANA_LABEL                    : 'フリガナ',
  MEMBER_REQUEST_FORM_TYPE_LABEL                        : '登録種類',
  MEMBER_REQUEST_FORM_OWNER_ASSOCIATION_LABEL           : '所属馬主協会',
  MEMBER_REQUEST_FORM_REPRESENTATIVE_LABEL              : '身元を証明する方',
  MEMBER_REQUEST_TEXT_FORMAT_ERROR                      : '全角、半角英数字・記号で入力してください。',
  MEMBER_EDIT_FORM_ADDRESS_LABEL                        : '住所',
  MEMBER_EDIT_FORM_FILE_LABEL                           : '添付ファイル',
  MEMBER_EDIT_FORM_FILE_CHANGING_LABEL                  : '※変更がある場合はお手数ですがお問い合わせください',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_LABEL              : 'インボイス登録番号',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_LABEL2             : '(国税庁から登録番号を取得済みの方のみ)',
  MEMBER_REQUEST_FORM_INVOICE_NUMBER_PLACEHOLDER        : '',

  MEMBER_REQUEST_FORM_TOP_CONTENT1                      : 'サタデーオークション会員登録申請',
  MEMBER_REQUEST_FORM_TOP_CONTENT2                      : 'サタデーオークションにご参加頂く為には事前に会員登録申請が必要となります。',
  MEMBER_REQUEST_FORM_TOP_CONTENT3                      : '下記より必要事項をご記入の上、申請をお願い致します。',
  MEMBER_REQUEST_FORM_TOP_CONTENT4                      : '※馬主・調教師以外の方が申請をされる場合は、身元を証明する方(調教師・馬主・牧場など)をご記入ください。',
  MEMBER_REQUEST_FORM_TOP_CONTENT5                      : '※申請をされた方にはご本人様確認のためオークション事務局から連絡させていただく場合があります。あらかじめご了承ください。',
  MEMBER_REQUEST_FORM_TOP_CONTENT6                      : '※公序良俗に反する言葉や他の方が不快に感じる可能性のある言葉をニックネームに使用することはお控えください。',
  MEMBER_REQUEST_FORM_TOP_CONTENT7                      : '※<span class="cred">必須</span>のついている項目は必ずご記入下さい。',
  MEMBER_REQUEST_FORM_TOP_CONTENT8                      : '主催者にて所定の審査を行い、会員登録が完了した方にはオークション事務局からメールでお知らせします。',
  MEMBER_REQUEST_FORM_TOP_CONTENT9                      : '会員登録申請はFAXでも受け付けております。',
  MEMBER_REQUEST_FORM_TOP_CONTENT10                     : '申請フォーム(',
  MEMBER_REQUEST_FORM_TOP_CONTENT11                     : ')に記入しFAXで077-546-5162までお送りください。',

  MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE            : '入力項目をご確認いただき、お間違いがなければ「申請する」をクリックしてください。',
  MEMBER_REQUEST_CONFIRM_NICKNAME_LABEL                 : 'ニックネーム',
  MEMBER_REQUEST_CONFIRM_NAME_LABEL                     : '氏名',
  MEMBER_REQUEST_CONFIRM_FURIGANA_LABEL                 : 'フリガナ',
  MEMBER_REQUEST_CONFIRM_TYPE_LABEL                     : '登録種類',
  MEMBER_REQUEST_CONFIRM_OWNER_ASSOCIATION_LABEL        : '所属馬主協会',
  MEMBER_REQUEST_CONFIRM_REGISTER_NUMBER_LABEL          : '馬主登録番号',
  MEMBER_REQUEST_CONFIRM_REPRESENTATIE_LABEL            : '身元を証明する方',
  MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL                : '郵便番号',
  MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL                  : '住所',
  MEMBER_REQUEST_CONFIRM_FAX_LABEL                      : 'FAX番号',
  MEMBER_REQUEST_CONFIRM_EMAIL_LABEL                    : 'メールアドレス',
  MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL            : 'メールアドレス<br class="only_pc">(確認用)',
  MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL                 : 'パスワード',
  MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL         : 'パスワード(確認用)',
  MEMBER_REQUEST_NEW_PASSWORD_LABEL                     : '新しいパスワード',
  MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL             : '新しいパスワード(確認用)',
  MEMBER_REQUEST_CURRENT_PASSWORD_LABEL                 : '現在のパスワード',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL                    : '個人情報の',
  MEMBER_REQUEST_CONFIRM_AGREE_LABEL2                   : '取り扱いについて',
  MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL              : '戻る',
  MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL              : '申請する',
  MEMBER_REQUEST_COMPLETION_MESSAGE                     : '会員登録申請が完了しました。',
  MEMBER_REQUEST_COMPLETION_MESSAGE2                    : '主催者での承認後にログインが可能になります。',
  MEMBER_REQUEST_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL    : 'ログイン画面に戻る',
  MEMBER_REQUEST_REPRESENTATIVE_LABEL2                  : '(調教師・馬主・牧場など)',
  MEMBER_EDIT_CONFIRM_EXPLANATION_MESSAGE               : '入力項目をご確認いただき、お間違いがなければ「更新する」をクリックしてください。',
  MEMBER_EDIT_CONFIRM_SEND_BUTTON_LABEL                 : '更新する',
  MEMBER_EDIT_COMPLETION_MESSAGE                        : '会員情報が更新されました。',

  REISSUE_PASSWORD_EXPLANATION_MESSAGE                  : 'パスワードをお忘れの方は会員登録に使用されたメールアドレスを入力してください。<br />「送信する」ボタンを押されると、仮パスワードが登録メールアドレスに届きます。',
  REISSUE_PASSWORD_CAUTION_MESSAGE                      : 'メールアドレスをお忘れの場合は事務局にお問い合わせください。',
  REISSUE_PASSWORD_FORM_ID_LABEL                        : 'ID',
  REISSUE_PASSWORD_FORM_EMAIL_LABEL                     : 'メールアドレス',
  REISSUE_PASSWORD_FORM_CONFIRM_EMAIL_LABEL             : 'メールアドレス(確認用)',
  REISSUE_PASSWORD_FORM_ID_PLACEHOLDER                  : '半角英数字',
  REISSUE_PASSWORD_FORM_EMAIL_PLACEHOLDER               : '半角英数字',
  REISSUE_PASSWORD_FORM_SUBMIT_LABEL                    : '送信する',
  REISSUE_PASSWORD_COMPLETION_MESSAGE                   : '新しいパスワードをメールアドレス宛に送信いたしました。',
  REISSUE_PASSWORD_COMPLETION_GO_TO_LOGIN_BUTTON_LABEL  : 'ログイン画面に戻る',
  REISSUE_PASSWORD_COMPLETION_GO_TO_TOP_BUTTON_LABEL    : 'トップ画面に戻る',

  AUCTION_PAGE_SEARCH_HEADER : 'オークション出展　検索条件',
  AUCTION_PAGE_HEADER_LABEL  : 'オークション出展',
  TENDER_PAGE_SEARCH_HEADER  : 'テンダー出展　検索条件',
  TENDER_PAGE_HEADER_LABEL   : 'テンダー出展',
  STOCK_PAGE_SEARCH_HEADER   : '在庫　検索条件',
  STOCK_PAGE_HEADER_LABEL    : '在庫',

  AUCTION_PAGE_SEARCH_TYPE : 'オークション',
  TENDER_PAGE_SEARCH_TYPE  : 'テンダー',
  STOCK_PAGE_SEARCH_TYPE   : '在庫',

  ITEM_SEARCH_KEYWORD_LABEL                          : 'キーワード',
  ITEM_SEARCH_KEYWORD_PLACEHOLDER                    : '※馬、騎手、調教師、生産牧場、馬主から馬情報を検索できます。',
  ITEM_SEARCH_INVENTORY_AREA_LABEL                   : '在庫地',
  ITEM_SEARCH_CATEGORY_LABEL                         : 'カテゴリ',
  ITEM_SEARCH_MODEL_YEAR_LABEL                       : '年式',
  ITEM_SEARCH_NAME_LABEL                             : '馬名',
  ITEM_SEARCH_NAME_PLACEHOLDER                       : '例)キーシーズン',
  ITEM_SEARCH_AGE_LABEL                              : '馬齢',
  ITEM_SEARCH_GENDER_LABEL                           : '性別',
  ITEM_SEARCH_HAIR_COLOR_LABEL                       : '毛色',
  ITEM_SEARCH_PRIDE_MONEY_LABEL                      : '総賞金',
  ITEM_SEARCH_PRICE_LABEL                            : '価格',
  ITEM_SEARCH_PRICE_UNIT                             : '万',
  ITEM_SEARCH_FAVORITE_LABEL                         : 'お気に入り',
  ITEM_SEARCH_BIDDING_LABEL                          : '入札中',
  ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL         : '最低落札超え',
  ITEM_SEARCH_SOLD_OUT_LABEL                         : 'SOLD OUT以外',
  ITEM_SEARCH_RECOMMEND_LABEL                        : 'オススメ',
  ITEM_SEARCH_NEW_LABEL                              : 'NEW',
  ITEM_SEARCH_SEARCH_BUTTON_LABEL                    : '検索',
  ITEM_SEARCH_CONDITIONS_LABEL                       : '条件',
  ITEM_SEARCH_SEARCH_RESULTS_LABEL                   : '検索結果',
  ITEM_SEARCH_SEARCH_RESULTS_SUBJECT_LABEL           : '件',
  ITEM_SEARCH_SEARCH_RESULTS_NUMBER_SUBJECT_LABEL    : '件',
  ITEM_SEARCH_CATEGORY_SEARCH_RESULTS_NUMBER_LABEL   : '件',
  ITEM_SEARCH_PRINT_ITEMS_CSV_BUTTON_LABEL           : '出展一覧リストCSV',
  ITEM_SEARCH_BULK_BIDDING_BUTTON_LABEL              : '一括入札',
  ITEM_SEARCH_CURRENT_PRICE_SORT_LABEL               : '現在価格',
  ITEM_SEARCH_RESULTS_LIMIT_LABEL                    : '表示件数',
  ITEM_SEARCH_BULK_BIDDING_MODAL_HEADER              : '一括入札',
  ITEM_SEARCH_CONFIRM_BIDDING_CANCEL_MODAL_HEADER    : '確認',
  ITEM_SEARCH_CONFIRM_BIDDING_MODAL_HEADER           : '入札内容の確認',
  ITEM_SEARCH_BULK_BIDDING_MODAL_BID_BUTTON_LABEL    : '入札を確定する',
  ITEM_SEARCH_BULK_BIDDING_MODAL_CANCEL_BUTTON_LABEL : '閉じる',
  ITEM_SEARCH_CONFIRM_BIDDING_CONFIRMED_LABEL        : '入札価格を確認しました',
  ITEM_SEARCH_BIDDING_MODAL_LOGIN_BUTTON_LABEL       : 'ログインする',
  ITEM_SEARCH_CONFIRM_BIDDING_LOGIN_REQUIRED_LABEL   : '会員限定で入札できます。ログインしてください。',

  ITEM_SEARCH_END_DATETIME_SORT_LABEL                : '終了時刻',
  ITEM_SEARCH_AGE_SORT_LABEL                         : '馬齢',
  ITEM_SEARCH_WEIGHT_SORT_LABEL                      : '前走馬体重',
  ITEM_SEARCH_TOTAL_PRIZE_MONEY_SORT_LABEL           : '総賞金',

  ITEM_SEARCH_TITLE_BAR                              : '現在オークション開催中です',
  ITEM_SEARCH_CONDITION_TITLE                        : '検索条件',
  ITEM_SEARCH_NO_CONDITION_LABEL                     : '無し',
  ITEM_SEARCH_RESULT_SORT_LABEL                      : '並べ替え',
  ITEM_SEARCH_REFRESH_BUTTON_LABEL                   : '価格更新',
  ITEM_SEARCH_RESET_BUTTON_LABEL                     : '検索条件をクリア',
  ITEM_SEARCH_NOT_FOUND_TEXT                         : '条件に該当する馬がいません。',

  ITEM_SEARCH_SOUTHKANTO_MOVEABLE_LABEL              : '南関東転入',
  ITEM_SEARCH_BAD_HABIT_LABEL                        : '悪癖',
  ITEM_SEARCH_INJURY_HISTORY_LABEL                   : '直近走以降の傷病',
  ITEM_SEARCH_TIMEOVER_LABEL                         : '直近走タイムオーバー',
  ITEM_SEARCH_FAVOURITE_LABEL                        : 'お気に入り登録',
  ITEM_SEARCH_KA_LABEL                               : '可',
  ITEM_SEARCH_FUKA_LABEL                             : '不可',
  ITEM_SEARCH_HI_LABEL                               : '否',
  ITEM_SEARCH_UMU_U_LABEL                            : 'あり',
  ITEM_SEARCH_UMU_MU_LABEL                           : 'なし',

  ITEM_ROW_TOP_STATUS_LABEL                       : 'あなたがTOP',
  ITEM_ROW_SECOND_STATUS_LABEL                    : '2位応札',
  ITEM_ROW_EXCEEDING_THE_LOWEST_BID_STATUS_LABEL  : '最低落札超え',
  ITEM_ROW_MORE_LITTLE_LABEL                      : 'あと少し',
  ITEM_ROW_CANCEL_LABEL                           : '出品停止中',
  ITEM_ROW_LOWEST_BID_PRICE_LABEL                 : '最低落札価格',
  ITEM_ROW_AUCTION_STATUS_LABEL                   : 'オークション',
  ITEM_ROW_MAKER_LABEL                            : 'メーカー',
  ITEM_ROW_YYYY_LABEL                             : '年式',
  ITEM_ROW_YYYY_UNIT_LABEL                        : '',
  ITEM_ROW_YYYY_NOT_CLEAR_LABEL                   : '',
  ITEM_ROW_MDLGR_LABEL                            : 'モデル',
  ITEM_ROW_OPETIM_LABEL                           : 'アワメータ',
  ITEM_ROW_OPETIM_NOT_CLEAR_LABEL                 : '',
  ITEM_ROW_ACTSERNO_LABEL                         : '号機',
  ITEM_ROW_CURRENT_PRICE_LABEL                    : '現在価格',
  ITEM_ROW_START_PRICE_LABEL                      : 'スタート価格',
  ITEM_ROW_PRICE_CURRENCY_MARK                    : '円',
  ITEM_ROW_PRICE_CURRENCY_MANYEN_MARK             : '万円',
  ITEM_ROW_WEIGHT_KG_MARK                         : 'kg',
  ITEM_ROW_START_STATUS_LABEL                     : '開始',
  ITEM_ROW_END_YOTEI_STATUS_LABEL                 : '終了予定',
  ITEM_ROW_END_STATUS_LABEL                       : '終了時刻',
  ITEM_ROW_ENDED_STATUS_LABEL                     : '終了',
  ITEM_ROW_SELLING_PRICE_LABEL                    : '販売価格',
  ITEM_ROW_SOLD_OUT_LABEL                         : 'SOLD OUT',
  ITEM_ROW_BID_PRICE_LABEL                        : '入札価格',
  ITEM_ROW_BID_BUTTON_LABEL                       : '入札する',
  ITEM_ROW_MEMBERS_ONLY_LABEL                     : '会員限定',
  ITEM_ROW_LOGIN_LINK_LABEL                       : 'ログイン',
  ITEM_ROW_NEW_MEMBER_REGISTRATION_BUTTON_LABEL   : '新規会員登録(無料)',
  ITEM_ROW_DETAIL_LINK_LABEL                      : '詳細',
  ITEM_ROW_BULK_BIDDING_MODAL_DELETE_BUTTON_LABEL : '削除',
  ITEM_ROW_BID_SUCCESS_MESSAGE                    : '入札を受け付けました。',
  ITEM_ROW_CANCEL_SUCCESS_MESSAGE                 : '入札取り消しを受け付けました。',
  ITEM_ROW_BID_CONFIRM_MESSAGE                    : 'お間違いなければ入札ボタンをクリックしてください。',
  ITEM_ROW_MULTIPLE_ITEMS_LABEL                   : '台セット',
  ITEM_ROW_BID_CURRENT_PRICE_ERROR_MESSAGE        : '現在価格以下の入札はできません。',
  ITEM_ROW_BID_LOWEST_PRICE_ERROR_MESSAGE         : 'スタート価格を下回る入札はできません。',
  ITEM_ROW_ITEM_CANCELLED_TEXT                    : '出品が取り消されたため入札できません。',
  ITEM_ROW_BID_CURRENT_1PITCH_ERROR_MESSAGE       : '現在価格+1ピッチ以上の入札をしてください。',
  ITEM_ROW_BID_CURRENT_2PITCH_ERROR_MESSAGE       : 'ピッチ幅に従い入札をしてください。',
  ITEM_ROW_BID_SUCCESS_MESSAGE_NOT_TOP            : '他の方がより高い金額で入札しています。落札するには、より高い金額で入札を行ってください。',
  ITEM_ROW_BID_SUCCESS_MESSAGE_NOT_TOP_SAME_PRICE : '他の方がすでに<span class="txt-err">同額</span>で入札しています。落札するには、より高い金額で入札を行ってください。',

  ITEM_ROW_NICKNAME_LABEL                         : '馬名',
  ITEM_ROW_GENDER_LABEL                           : '性別',
  ITEM_ROW_HAIR_COLOR_LABEL                       : '毛色',
  ITEM_ROW_BIRTHDAY_LABEL                         : '誕生日',
  ITEM_ROW_AGE_LABEL                              : '年齢',
  ITEM_ROW_WEIGHT_LABEL                           : '体重',
  ITEM_ROW_PRICE_WITH_TAX_LABEL                   : '税込価格',
  ITEM_ROW_PRICE_WITHOUT_TAX_LABEL                : '税別価格',
  ITEM_ROW_BID_COUNT_LABEL                        : '現在の入札数',
  ITEM_ROW_AGE_0_LABEL                            : '当歳',

  AUCTION_DETAIL_PAGE_NAME : 'オークション詳細',
  STOCK_DETAIL_PAGE_NAME   : '在庫詳細',
  TENDER_DETAIL_PAGE_NAME  : 'テンダー詳細',

  DETAIL_LOWEST_BID_ACCEPT_PRICE_LABEL           : 'スタート価格',
  DETAIL_BID_STATUS_LABEL                        : '入札状況',
  DETAIL_BID_STATUS_UNBID_LABEL                  : '未入札',
  DETAIL_BID_STATUS_BIDED_LABEL                  : '入札済み',
  DETAIL_BID_STATUS_MORE_LITTLE_LABEL            : 'あと少し',
  DETAIL_BID_STATUS_SUCCESSFUL_BID_RIGHT_LABEL   : '落札権あり',
  DETAIL_BID_AUTOMATIC_BIDDING_LABEL             : '自動入札',
  DETAIL_DOWNLOAD_IMAGES_BUTTON_LABEL            : '画像を一括ダウンロード',
  DETAIL_CONTACT_PRODUCT_BUTTON_LABEL            : 'この馬について問い合わせる',
  DETAIL_ACHIEVEMENT_REPORT_BUTTON_LABEL0        : '基本情報を見る',
  DETAIL_ACHIEVEMENT_REPORT_BUTTON_LABEL1        : '競走成績を見る',
  DETAIL_PRODUCT_INFORMATION_LABEL               : '馬情報',
  DETAIL_AWAMETER_LABEL                          : 'アワメータ',
  DETAIL_MILEAGE_LABEL                           : '走行距離',
  DETAIL_STOCK_LOCATION_LABEL                    : '在庫場所',
  DETAIL_VEHICLE_INSPECTION_LABEL                : '車検',
  DETAIL_MAXIMUM_RATED_TOTAL_LOAD_LABEL          : '最大定格総荷重',
  DETAIL_CR_INSPECTION_LABEL                     : 'CR検査',
  DETAIL_SPECIFICATION_LABEL                     : '仕様',
  DETAIL_HOOK_LABEL                              : 'フック',
  DETAIL_OUTRIGGER_LABEL                         : 'アウトリガー',
  DETAIL_REMARKS_LABEL                           : '備考',
  DETAIL_CONTACT_PRODUCT_BOTTOM_BUTTON_LABEL     : '馬に関するお問い合わせ',
  DETAIL_CLOSE_BUTTON_LABEL                      : '閉じる',
  DETAIL_NOTE_TEXT_TOP                           : '置き場現状有姿渡し・先売優先・保証なし',
  DETAIL_NOTE_TEXT_BOTTOM                        : '重要：保管場所にご注意ください。',
  DETAIL_LIST_OF_STORAGE_LOCATIONS_BUTTON_LABEL  : '保管場所一覧',
  DETAIL_ITEM_LOT_ITEMS_PS_TEXT                  : '※このロットには複数の馬が含まれています。',
  DETAIL_ITEM_PREV_BUTTON_LABEL                  : '前へ',
  DETAIL_ITEM_NEXT_BUTTON_LABEL                  : '次へ',
  DETAIL_ITEM_SNS_SHARE_LABEL                    : 'この馬をシェアする',
  DETAIL_ITEM_HOLDING_PERIOD_LABEL               : '開催期間',
  DETAIL_ITEM_SNS_SHARE_TEXT                     : '',
  DETAIL_ITEM_BID_REMAINING_TIME                 : '残り時間',
  DETAIL_ITEM_BID_EXTENDED_END                   : '延長終了',
  DETAIL_ITEM_BID_END_TIME_LABEL                 : '終了時刻',
  DETAIL_ITEM_BID_BEFORE_START_LABEL             : '開始前',
  DETAIL_ITEM_BID_COUNTDOWN_DAY_LABEL            : '日 ',
  DETAIL_ITEM_BID_COUNTDOWN_HOUR_LABEL           : '時間',
  DETAIL_ITEM_BID_COUNTDOWN_MINUTE_LABEL         : '分',
  DETAIL_ITEM_BID_COUNTDOWN_SECOND_LABEL         : '秒',

  DETAIL_CATEGORY_LABEL                          : 'カテゴリー',
  DETAIL_OWNER_LABEL                             : '馬主',
  DETAIL_BIRTHDAY_LABEL                          : '生年月日',
  DETAIL_WEIGHT_LABEL                            : '前走馬体重',
  DETAIL_FATHER_LABEL                            : '父',
  DETAIL_MOTHER_LABEL                            : '母',
  DETAIL_MATERNAL_GRANDFATHER_LABEL              : '母の父',
  DETAIL_TOTAL_ACHIEVEMENT_LABEL                 : '通算成績',
  DETAIL_CENTRAL_PRIZE_MONEY_LABEL               : '中央獲得賞金',
  DETAIL_LOCAL_PRIZE_MONEY_LABEL                 : '地方獲得賞金',
  DETAIL_RANCHING_LABEL                          : '牧場',
  DETAIL_TOP_BID_MEMBER                          : 'TOP入札者',
  DETAIL_BID_PRICE_TAX_LABEL                     : '税込',
  DETAIL_BID_COUNT_LABEL                         : '入札数',
  DETAIL_BID_HISTORY_LABEL                       : '入札履歴を見る',
  DETAIL_BID_CURRENT_CANDIDATE_LABEL             : '現在の候補者',
  DETAIL_BID_CURRENT_CANDIDATE_SAN_LABEL         : 'さん',
  DETAIL_BID_START_DATE_TIME_LABEL               : '開始時刻',
  DETAIL_BID_START_PRICE_LABEL                   : '開始価格',
  DETAIL_BID_PRICE_GUILD_LABEL                   : '入札価格は税別の金額を入力してください',
  DETAIL_ITEM_HORSE_MEMO_LABEL                   : '本馬のご紹介',
  DETAIL_ITEM_FOREIGN_HORSE_LABEL                : '外国産馬',
  DETAIL_ITEM_FOREIGN_HORSE_SUBFIX               : '生',
  DETAIL_ITEM_REGISTER_SELLER_LABEL              : '販売申込者',
  DETAIL_ITEM_JRA_DIRT_LABEL                     : 'JRAダート',
  DETAIL_ITEM_JRA_DIRT_WIN_LABEL                 : '勝',
  DETAIL_ITEM_JRA_DIRT_MATCH_LABEL               : '戦',
  DETAIL_ITEM_JRA_DIRT_NO_MATCH_LABEL            : '未出走',

  DETAIL_BID_HISTORY_TITLE                       : '入札履歴',
  DETAIL_BID_HISTORY_BID_TIME                    : '入札時刻',
  DETAIL_BID_HISTORY_BID_PRICE                   : '入札価格',
  DETAIL_BID_HISTORY_NICKNAME                    : 'ニックネーム',

  CONTACT_PAGE_NAME                  : 'お問い合わせ',
  ITEM_CONTACT_PAGE_NAME             : '中古馬のお問い合わせ',
  CONTACT_FORM_INFO_TEXT             : '以下の項目をご入力ください。<em class="req">必須</em>のついている項目は必ずご入力ください。',
  CONTACT_FORM_CONTACT_CONTENT_LABEL : 'お問い合わせ内容',
  CONTACT_EXPORT_FILE_LABEL          : '添付ファイル',
  CONTACT_FORM_GUEST_TEXT            : 'ゲスト',
  CONTACT_FORM_UDRCARR_LABEL         : '足回り',
  CONTACT_FORM_CAB_LABEL             : '運転室',
  CONTACT_FORM_CLASS_LABEL           : 'クラス',
  CONTACT_FORM_AREA_LABEL            : '保管場所',
  CONTACT_COMPLETION_MESSAGE         : 'お問い合わせが完了しました。',

  MY_PAGE_SUCCESSFUL_BID_HISTORY_SEARCH_CONDITION_VIEW_HEADER : '落札履歴　検索条件',
  MY_PAGE_SEARCH_CONDITION_METHOD_LABEL                       : '方式',
  MY_PAGE_SEARCH_CONDITION_BIDDING_DATE_LABEL                 : '入札会終了日',
  MY_PAGE_SEARCH_CONDITION_BIDDING_FROM_DATE_PLACEHOLDER      : '例)2021/01/01',
  MY_PAGE_SEARCH_CONDITION_BIDDING_TO_DATE_PLACEHOLDER        : '例)2021/01/01',
  MY_PAGE_SEARCH_BUTTON_LABEL                                 : '検索',
  MY_PAGE_SEARCH_CONDITION_LABEL                              : '条件',
  MY_PAGE_SEARCH_RESULT_LABEL                                 : '検索結果',
  MY_PAGE_SEARCH_RESULT_COUNT_LABEL                           : '{count}件',
  MY_PAGE_SEARCH_RESULT_DISPLAY_COUNT_LABEL                   : '表示件数',
  MY_PAGE_SEARCH_RESULT_SORT_DISPLAY_COUNT_LABEL              : '{display_count}件',
  MY_PAGE_ITEM_BIDDING_END_DATETIME_LABEL                     : '入札会終了日時',
  MY_PAGE_COLON_LABEL                                         : ':',
  MY_PAGE_ITEM_MAKER_LABEL                                    : 'メーカー',
  MY_PAGE_ITEM_MODEL_LABEL                                    : 'モデル',
  MY_PAGE_ITEM_YEAR_LABEL                                     : '年式',
  MY_PAGE_ITEM_OPERATING_TIME_LABEL                           : 'アワメータ',
  MY_PAGE_ITEM_MODEL_SERIAL_NUMBER_LABEL                      : '号機',
  MY_PAGE_ITEM_CONTRACT_PRICE_LABEL                           : '落札価格',
  MY_PAGE_ITEM_BID_PRICE_LABEL                                : '入札価格',
  MY_PAGE_ITEM_CONTRACT_BIDDER_LABEL                          : '最終入札者',
  MY_PAGE_ITEM_PRODUCT_IMAGE_DOWNLOAD_BUTTON_LABEL            : '商品画像ダウンロード',
  MY_PAGE_SHOW_MORE_BUTTON_LABEL                              : 'もっと見る',
  MY_PAGE_PRICE_CURRENCY_MARK                                 : '￥',
  MY_PAGE_YEAR_MARK                                           : '',
  MY_PAGE_NO_MARK                                             : 'No.',
  MY_PAGE_SHOW_ITEMS_LINK_LABEL                               : 'ロットの内訳',
  MY_PAGE_HIDE_ITEMS_LINK_LABEL                               : '内訳を閉じる',
  MY_PAGE_UPDATE_FILE_SIZE_LIMIT_ERROR_MESSAGE                : '5MB以内のファイルをアップロードしてください。',

  MY_PAGE_HISTORY_HEADER_TEXT                                 : '過去の落札馬',
  MY_PAGE_HISTORY_HORSE_NAME_LABEL                            : '落札馬名',
  MY_PAGE_HISTORY_BID_SUCCESSFUL_DATE_LABEL                   : '落札日',
  MY_PAGE_HISTORY_BID_SUCCESSFUL_PRICE_LABEL                  : '落札価格(税別)',
  MY_PAGE_HISTORY_DETAIL_BUTTON_LABEL                         : '詳細',
  MY_PAGE_HISTORY_BOTTOM_TEXT                                 : '落札日より1年を経過した馬は表示されません。',

  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT      : '退会のご確認',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT     : '本当に退会されますか',
  CHANGE_INFO_WITHDRAW_MEMBER_SUCCESS_MESSAGE1                : '退会の手続きが完了しました。',
  CHANGE_INFO_WITHDRAW_MEMBER_SUCCESS_MESSAGE2                : 'サタデーオークションをご利用頂きありがとうございました。',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL         : 'はい',
  CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL     : 'いいえ',

  OTHERS_PROFILE_TEXT                           : '会社概要',
  OTHERS_PROFILE_COMPANY_NAME_LABEL             : '会社名',
  OTHERS_PROFILE_COMPANY_NAME_TEXT              : '株式会社エクモ',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_LABEL       : '英文社名',
  OTHERS_PROFILE_COMPANY_NAME_OTHER_TEXT        : 'Eqmo Corporation',
  OTHERS_PROFILE_DIRECTOR_NAME_LABEL            : '代表取締役社長',
  OTHERS_PROFILE_DIRECTOR_NAME_TEXT             : '田中 敬太 （たなか けいた）',
  OTHERS_PROFILE_ADDRESS_LABEL                  : '所在地',
  OTHERS_PROFILE_POST_MARK                      : '〒520-2279',
  OTHERS_PROFILE_ADDRESS_TEXT                   : '滋賀県大津市黒津三丁目8-21',
  OTHERS_PROFILE_CAPITAL_LABEL                  : '資本金',
  OTHERS_PROFILE_CAPITAL_TEXT                   : '300万円',
  OTHERS_PROFILE_STOCKHOLDER_LABEL              : '株主構成',
  OTHERS_PROFILE_STOCKHOLDER_TEXT               : '',
  OTHERS_PROFILE_FOUNDED_LABEL                  : '創立時期',
  OTHERS_PROFILE_FOUNDED_TEXT                   : '2017年10月',
  OTHERS_PROFILE_BUSINESS_LABEL                 : '事業内容',
  OTHERS_PROFILE_BUSINESS_TEXT                  : '・エスカレーター手すり除菌装置の輸入販売、保守管理及びメンテナンス<br/>・スポーツ関連の国際大会出場に関するコンサルティング及びコーディネイト<br/>・コンピュータシステム、ソフトウェア及びデジタルコンテンツの企画製作、販売、保守及びコンサルティング<br/>・情報処理、会計、文書作成等の事務処理請負<br/>・小売店向け経理・会計ソリューションの販売及びコンサルティング',

  OTHERS_PRIVACY_TEXT                           : '個人情報の保護',
  OTHERS_PRIVACY_MAIN_1_TEXT                    : 'テスト',

  GUIDE_PAGE_TITLE_1                            : 'サラブレッドオークション取引完了までの流れ',

  FAQ_TITLE_TEXT                                : 'ルールとFAQ',

  SYSTEM_ERROR_MESSAGE                          : 'システムエラーが発生しました。'
}
