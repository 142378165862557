import Methods from '@/api/methods'
import Base from '@/common/base'

export default {
  data() {
    return {
      errors        : [],
      inputBidPrice : this.getBidPrice(),
      isFavorite    : this.getInitFavorite()
    }
  },
  watch : {
    'item.bid_status'(value) {
      console.log('item.bid_status changed: ', value)
      // 価格更新ボタンを押下する時に入札価格を再計算する
      this.inputBidPrice = this.getBidPrice()
    },
    'item.attention_info'(value) {
      console.log('item.attention_info changed: ', value)
      // Favourite flag updated
      if (this.item && this.item.attention_info) {
        this.isFavorite = this.item.attention_info.is_favorited
      }
    }
  },
  methods : {
    addPitch(pitch) {
      console.log('addPitch')
      if (this.errorMessages && this.errorMessages.length > 0) {
        this.errorMessages.splice(0, this.errorMessages.length)
      }
      this.errors = []
      const bidPrice = Number(this.inputBidPrice ? Base.localeString2Number(this.inputBidPrice) : parseInt(this.item.bid_status.current_price, 10))
      const newPrice = this.getNext1Pitch(bidPrice)
      this.inputBidPrice = this.bidPriceLocaleString(newPrice)
    },
    subtractPitch(pitch) {
      console.log('subtractPitch')
      if (this.errorMessages && this.errorMessages.length > 0) {
        this.errorMessages.splice(0, this.errorMessages.length)
      }
      this.errors = []

      if (this.inputBidPrice === '0' || this.inputBidPrice === '') {
        this.inputBidPrice = ''
      } else {
        const bidPrice = Number(this.inputBidPrice ? Base.localeString2Number(this.inputBidPrice) : parseInt(this.item.bid_status.current_price, 10))
        const newPrice = this.getPrev1Pitch(bidPrice)
        this.inputBidPrice = this.bidPriceLocaleString(newPrice)
      }
    },
    getPitchByPrice(bidPrice, isAdd = true) {
      /*
       * Add:
       * 5,000,000の[＋]は、5,100,000
       * 5,100,000の[＋]は、5,200,000
       * Subtract:
       * 1,000,000の[－]は、990,000
       * 2,000,000の[－]は、1,980,000
       * 5,000,000の[－]は、4,950,000
       */
      const tmp_pitch = this.getPitchData(bidPrice, isAdd)
      return tmp_pitch ? Number(tmp_pitch.value1) : 0
    },
    getPitchData(price, isAdd) {
      /*
       * Add:
       * 5,000,000の[＋]は、5,100,000
       * 5,100,000の[＋]は、5,200,000
       * Subtract:
       * 1,000,000の[－]は、990,000
       * 2,000,000の[－]は、1,980,000
       * 5,000,000の[－]は、4,950,000
       */
      let tmp_pitch = null
      const autoPitches = this.grandParent.autoPitchConstants || this.greatGrandParent.autoPitchConstants
      if (autoPitches) {
        autoPitches.map(pitch => {
          // Low
          const low = typeof pitch.value3 === 'undefined' || pitch.value3 === null || pitch.value3.length === 0
                     || ((isAdd && price >= Number(pitch.value3)) || (!isAdd && price > Number(pitch.value3)))

          // High
          const high = typeof pitch.value4 === 'undefined' || pitch.value4 === null || pitch.value4.length === 0
                     || ((isAdd && price < Number(pitch.value4)) || (!isAdd && price <= Number(pitch.value4)))
          if (low && high) {
            tmp_pitch = pitch
          }
        })
      }
      return tmp_pitch
    },
    getNext1Pitch(price) {
      // Plusボタン押すときに次の１Pitchを取得する
      let pitchData = null
      let low = this.item.bid_status.current_price
      let autoPitchWidth = this.item.bid_status.pitch_width

      // 入札金額帯の場合
      if (this.item.bid_status.pitch_option === 0) {
        pitchData = this.getPitchData(price, true)
        if (pitchData) {
          low = Number(pitchData.value3)
          autoPitchWidth = Number(pitchData.value1)
        }
      }

      let newBidPrice = price
      const nextPitch = parseInt((newBidPrice - low) / autoPitchWidth, 10)
      newBidPrice = low + ((nextPitch + 1) * autoPitchWidth)

      // Maximum 9桁のため
      if (newBidPrice > 999999999) {
        newBidPrice = low + (nextPitch * autoPitchWidth)
      }

      return newBidPrice
    },
    getPrev1Pitch(price) {
      // Plusボタン押すときに次の１Pitchを取得する
      let pitchData = null
      let low = this.item.bid_status.current_price
      let autoPitchWidth = this.item.bid_status.pitch_width

      // 入札金額帯の場合
      if (this.item.bid_status.pitch_option === 0) {
        pitchData = this.getPitchData(price, false)
        if (pitchData) {
          low = Number(pitchData.value3)
          autoPitchWidth = Number(pitchData.value1)
        }
      }

      let prevPitch = parseInt((price - low) / autoPitchWidth, 10)
      if (price <= (low + (prevPitch * autoPitchWidth))) {
        prevPitch -= 1
      }
      const newBidPrice = low + (prevPitch * autoPitchWidth)

      return newBidPrice
    },
    correctInputPriceByPitch(price) {
      // Plusボタン押すときに次の１Pitchを取得する
      let pitchData = null
      let low = this.item.bid_status.current_price
      let autoPitchWidth = this.item.bid_status.pitch_width

      // 入札金額帯の場合
      if (this.item.bid_status.pitch_option === 0) {
        pitchData = this.getPitchData(price, true)
        if (pitchData) {
          low = Number(pitchData.value3)
          autoPitchWidth = Number(pitchData.value1)
        }
      }

      const prevPitch = parseInt((price - low) / autoPitchWidth, 10)
      const correctBidPrice = low + (prevPitch * autoPitchWidth)

      return correctBidPrice
    },
    bidItemClick(typeTag) {
      if (typeTag === 'TENDER_PAGE_SEARCH_TYPE') {
        if (this.inputBidPrice === '') {
          this.bidItem()
        } else {
          this.openBidConfirmDialog(this.item.exhibition_item_no, this.inputBidPrice)
        }
      } else if (typeTag === 'AUCTION_PAGE_SEARCH_TYPE') {
        if (this.inputBidPrice === '') { //  || this.inputBidPrice === '0'
          if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
            this.bidItem()
          } else {
            this.openCancelBidDialog(null)
          }
        } else if ((this.item.attention_info.bid_count > 0 && Base.localeString2Number(this.inputBidPrice) <= this.item.bid_status.current_price)
          || (this.item.attention_info.bid_count === 0 && Base.localeString2Number(this.inputBidPrice) < this.item.bid_status.current_price)) {
          /*
           * 入札するボタンを押したときに
           * 「現在価格以下の入札はできません。」
           * 「スタート価格を下回る入札はできません。」
           */
          if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)) {
            const prcError = [
              {
                errorMessage : this.item.attention_info.bid_count > 0 ? this.$t('ITEM_ROW_BID_CURRENT_PRICE_ERROR_MESSAGE') : this.$t('ITEM_ROW_BID_LOWEST_PRICE_ERROR_MESSAGE')
              }
            ]
            this.openCancelBidDialog(prcError)
          } else {
            this.openCancelBidDialog(null)
          }
        } else {
          /*
           * 最低入札可能額は超えているが、入札ピッチに従わない価格での入札が試みられた場合
           *  →その額からすぐ下の入札ピッチに合った金額を自動的に表示させ、チェックボックスの押下が可能な状態にする。
           */
          const bidPrice = Base.localeString2Number(this.inputBidPrice)
          this.inputBidPrice = this.bidPriceLocaleString(this.correctInputPriceByPitch(bidPrice))

          /*
           * 補正した結果現在価格を下回った場合はエラーを出す。
           *  「現在価格+1ピッチ以上の入札をしてください。」
           */
          let low = this.item.bid_status.current_price
          let autoPitchWidth = this.item.bid_status.pitch_width

          // Get next 1 pitch from current price
          let currentNext1PitchPrice = this.item.bid_status.current_price
          if (this.item.bid_status.pitch_option === 0) {
            // 入札金額帯
            const currentPricePitchData = this.getPitchData(this.item.bid_status.current_price, true)
            if (currentPricePitchData) {
              low = Number(currentPricePitchData.value3)
              autoPitchWidth = Number(currentPricePitchData.value1)
              currentNext1PitchPrice = low + ((((currentNext1PitchPrice - low) / autoPitchWidth) + 1) * autoPitchWidth)
            }
          } else {
            // 指定単位
            currentNext1PitchPrice += autoPitchWidth
          }

          if (this.item.attention_info.bid_count > 0 && bidPrice < currentNext1PitchPrice) {
            const errorCode = 'ITEM_ROW_BID_CURRENT_1PITCH_ERROR_MESSAGE'
            const prcError = [
              {
                errorMessage : this.$t(errorCode)
              }
            ]
            this.openCancelBidDialog(prcError)
          } else {
            this.openBidConfirmDialog(this.item.exhibition_item_no, this.inputBidPrice)
          }
        }
      }
    },
    openCancelBidDialog(errors) {
      this.$emit('cancelBidDialog', errors)
    },
    openBidConfirmDialog(itemNo, newBidPrice) {
      this.$emit('bidConfirm', itemNo, newBidPrice)
    },
    bidItem() {
      this.$store.state.loading = true
      this.errors = []
      const params = {
        bidItems : [
          {
            exhibitionItemNo : this.item.exhibition_item_no,
            bidPrice         : this.inputBidPrice === '' ? null : Base.localeString2Number(this.inputBidPrice)
          }
        ]
      }
      Methods.apiExcute('private/bid-items', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.item.changed = false
        this.updateViewAfterBid(data.bidList.filter(bid => !bid.errorMessage))

        // Open Bid Cancel dialog
        let tmp_errors = data.bidList.filter(bid => bid.errorMessage)
        // 入札と取消が成功の場合にメッセージを表示する
        if (tmp_errors.length <= 0) {
          if (params.bidItems[0].bidPrice === 0) {
            // 取り消す
            tmp_errors = [
              {
                success      : true,
                errorMessage : this.$t('ITEM_ROW_CANCEL_SUCCESS_MESSAGE')
              }
            ]
          } else {
            // 入札
            tmp_errors = [
              {
                success      : true,
                errorMessage : this.$t('ITEM_ROW_BID_SUCCESS_MESSAGE')
              }
            ]
          }
        }
        this.openCancelBidDialog(tmp_errors)
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    favoriteItem(favorited) {
      console.log('favoriteItem')
      if (this.$cookies.get(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN) === null) {
        return
      }
      this.$store.state.loading = true
      const params = {
        favorited,
        exhibition_item_no : this.item.exhibition_item_no
      }
      this.favorite(params)
        .then(data => {
          this.$store.state.loading = false
          const favChangedItems = [
            {
              exhibition_item_no : this.item.exhibition_item_no,
              favorited
            }
          ]
          this.updateFavoriteChangedItems(favChangedItems)
        })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    reloadFavoriteChangedItems(favItems) {
      console.log('reloadFavoriteChangedItems')
      if (favItems) {
        if (favItems !== 'reload') {
          favItems.map(fav => {
            if (this.item.exhibition_item_no === fav.exhibition_item_no) {
              this.item.attention_info.is_favorited = !fav.favorited
              this.item.attention_info.favorited_count += fav.favorited ? -1 : 1
            }
            this.isFavorite = this.item.attention_info.is_favorited
          })
        }
      }
    },
    getBidPrice() {
      /*
       * 入札できる価格を表示する
       *  現在価格 NULL: 開始価格
       *  現在価格 Not null: 現在価格 ＋ １Pitch
       * let bidPrice = this.item && this.item.inputBidPrice ? this.item.inputBidPrice : this.item && this.item.bid_status && this.item.bid_status.current_price ? this.item.bid_status.current_price : ''
       */
      let bidPrice = this.item && this.item.bid_status && this.item.bid_status.current_price ? this.item.bid_status.current_price : ''

      /*
       * TOP会員の場合はtop_price + 1pitch
       * TOP以外はcurrent_price + 1pitch
       */
      if (this.item && this.item.bid_status && this.item.bid_status.is_top_member) {
        bidPrice = this.item.bid_status.top_price
      }

      bidPrice = Base.localeString2Number(bidPrice)
      if (this.item && this.item.bid_status && this.item.bid_status.is_exceeding_lowest_price) {
        bidPrice = this.getNext1Pitch(bidPrice)
      }
      return this.bidPriceLocaleString(bidPrice)
    },
    getInitFavorite() {
      const isFav = this.item && this.item.attention_info && this.item.attention_info.is_favorited
      return isFav
    },
    reloadItemStatus() {
      this.$store.state.loading = true
      const params = {
        exhibitionItemNo : this.item.exhibition_item_no
      }
      Methods.apiExcute('private/reload-item-status', this.$i18n.locale, params).then(data => {
        this.$store.state.loading = false
        this.item = Object.assign(this.item, data)
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getAreaName(areaId) {
      const areas = this.areaConstants || this.parent.areaConstants
      if (areas) {
        const filtered_areas = areas.filter(x => x.value1 === areaId)
        return filtered_areas.length > 0 ? filtered_areas[0].value2 : ''
      }
      return ''
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode === 13 || ((charCode >= 31 && (charCode < 48 || charCode > 57)) && charCode !== 46)) {
        evt.preventDefault()
      }
      return true
    },
    priceLocaleString(event) {
      const bidPrice = event.target.value
      if (!bidPrice || bidPrice === '') {
        return ''
      }
      /*
       * If (Number(bidPrice) === 0) {
       *   return '0'
       * }
       */
      return this.bidPriceLocaleString(bidPrice)
    },
    onFocusout_start_price(event) {
      return this.priceLocaleString(event)
    },
    onFocusin_start_price(event) {
      const bidPrice = event.target.value
      return bidPrice ? Base.localeString2Number(bidPrice) : ''
    },
    bidPriceLocaleString(value) {
      const commaPrice = this.bidConfirmPrice(value)
      return commaPrice
    },
    bidConfirmPrice(value) {
      const num = Base.localeString2Number(value)
      const commaPrice = num.toLocaleString()
      return commaPrice
    },
    isTosaiBa(free_field) {
      return free_field && (String(free_field.age) === '0' || String(free_field.category) === '1')
    }
  }
}
