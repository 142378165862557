<template>
<countdown :left-time="1000*Number(remainingSeconds)" :auto-start="true">
  <p
    class="fade"
    slot="process"
    slot-scope="model"
  >
    {{ timeRemainingCal(model.timeObj.ceil.s) }}
  </p>
  <div slot="finish">
    <p class="countdown-text">{{timeFinish()}}</p>
  </div>
</countdown>
</template>

<script>

export default {
  props : {
    remainingSeconds : {
      type    : Number,
      default : Object
    },
    endDatetime : {
      type    : String,
      default : ''
    },
    daySplitter : {
      type    : String,
      default : 'Days '
    },
    hourSplitter : {
      type    : String,
      default : ':'
    },
    minuteSplitter : {
      type    : String,
      default : ':'
    },
    secondSplitter : {
      type    : String,
      default : ''
    },
    isTopScreen : {
      type    : Boolean,
      default : true
    },
    extending : {
      type    : Boolean,
      default : false
    }
  },
  methods : {
    timeRemainingCal(seconds) {
      let result = ''
      if (seconds > 0) {
        const day = Math.floor(((seconds / 60) / 60) / 24)
        const hour = Math.floor(((seconds / 60) / 60) % 24)
        const min = Math.floor((seconds / 60) % 60)
        const sec = Math.floor(seconds % 60)
        // Days
        if (day === 0) {
          result += ''
        } else if (day < 2) {
          result += day + this.daySplitter
        } else {
          result += day + this.daySplitter
        }
        // Hours
        if (hour === 0) {
          result += '' // Dont show 0 hour
        } else if (hour < 10) {
          result += `${hour}${this.hourSplitter}` // 01:22 -> 1時間22
        } else {
          result += hour + this.hourSplitter
        }
        // Minutes
        if (min < 10) {
          result += `${min}${this.minuteSplitter}` // 01:02 -> 1:2
        } else {
          result += min + this.minuteSplitter
        }
        // Seconds
        if (sec < 10) {
          result += (this.isTopScreen ? '0' : '') + sec + this.secondSplitter // Dont add 0 when in detail screen: 0分2秒、TOP screen: 0:02
        } else {
          result += sec + this.secondSplitter
        }
        // 「4:59(延長中)」を表示する
        if (this.extending) {
          result += '(延長中)'
        }
      }
      return result
    },
    timeFinish() {
      return this.remainingSeconds >= 0
        ? (`0${this.minuteSplitter}0${this.isTopScreen ? '0' : ''}${this.secondSplitter}${this.extending ? '(延長中)' : ''}`)
        : this.endDatetime
    }
  }
}
</script>
<style scoped lang="scss">
div.fade {
  animation: blink 1s ease-in-out infinite alternate;
  text-align: center;
}

@keyframes blink {
  from { color: #7D7D7D; }
  to { color: #f00; }
}

.countdown-text {
  color: #F15A24;
  text-align: center;
}
</style>
