<template>
<div class="horse_introduction">
    <template v-for="(image, index) in images">
      <div class="itemPhotos" :key="index">
        <video-player
          v-if="image.postar_file_path"
          :key="`video_${image.file_path}`"
          :src="image.file_path | getFullImagePath"
          :poster="image.postar_file_path | getFullImagePath"
        />
        <img
          v-else
          :key="`img_${image.file_path}`"
          :src="image.file_path | getFullImagePath" srcset=""
          :poster="image.postar_file_path | getFullImagePath"
        />
      </div>
    </template>
</div>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import VideoPlayer from './VideoPlayer.vue'
export default {
  name       : 'MediaBox',
  components : {
    VideoPlayer
  },
  props : {
    images : {
      type    : Array,
      default : Object
    }
  },
  mixins  : [CommonFilters],
  methods : {
    videoPlaying(event) {
      console.log(event.type)
      if (event.target.ended) {
        console.log('ended -> re-load')
        event.target.load()
        event.target.play()
      }
    }
  }
}
</script>
<style scoped>
  .itemPhotos {
    text-align: center;
    vertical-align: middle;
    width: auto;
    height: 100%;
    border: solid 1px #fbfbfb;
    background-color: #fbfbfb;
  }
  .itemPhotos {
    margin-bottom: 20px;
  }
  .itemPhotos img {
    width: auto;
    height: auto;
    vertical-align: middle;
  }
  .horse_introduction img {
    margin-bottom: 0 !important;
  }
</style>
<style scoped src="@/assets/css/contents01.css"></style>
