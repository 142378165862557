<template>
<li class="horse_list_card" date-horse="a1234567" v-bind:class="{'new-item': item.is_new}">
  <div class="horse_li_img">
    <div class="horse_li_base">
      <img v-if="item.image && item.image.length > 0 && item.image.find(file => !file.postar_file_path && file.file_path)"  :src="item.image.find(file => !file.postar_file_path && file.file_path).file_path | getFullImagePath"  class="horse_thum"/>
      <img v-else src="@/assets/images/no_photo.jpg" class="horse_thum"/>
      <button
        v-if="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN) !== null && item.attention_info"
        :value="$t('ITEM_SEARCH_FAVORITE_LABEL')"
        class="horse_li_fav btn_fav"
        :data-fav="isFavorite ? 'on' : 'off'"
        @click.stop="favoriteItem(item.attention_info.is_favorited)"
      />
      <p v-if="item.bid_status && item.bid_status.is_top_member" class="bidder" data-bid="top"></p>
      <p v-if="item.bid_status && item.bid_status.is_second_member" class="bidder" data-bid="second"></p>
    </div>
  </div>
  <div class="horse_li_txt">
    <p class="horse_li_prof u-pt5">
      <span v-if="item.free_field" class="name">{{item.free_field.nickname}}</span>
      <span v-if="item.free_field" class="sex">{{getGender(item.free_field.gender)}}</span>
      <span v-if="item.free_field" class="year">{{isTosaiBa(item.free_field) ? $t('ITEM_ROW_AGE_0_LABEL') : ((typeof item.free_field.age) !== 'undefined' ? item.free_field.age + $t('TOP_PAGE_AGE_LABEL') : '')}}</span>
      <span v-if="item.free_field && !isTosaiBa(item.free_field)" class="category">{{getCategory(item.free_field.category, 3, 0)}}</span>
    </p>
    <div v-if="item.bid_status">
      <p class="auction_end" v-if="item.bid_status.extending">
        {{$t("DETAIL_ITEM_BID_REMAINING_TIME")}}
        <span class="auction_end_time">
          <ExtendCountDown
            :isTopScreen="true"
            :remainingSeconds="item.bid_status.remaining_seconds"
            :endDatetime="item.bid_status.end_datetime | timeFormat"
            :key="item.bid_status.remaining_seconds"
            :extending="item.bid_status.extending"
            secondSplitter=""
          />
        </span>
      </p>
      <p class="auction_end" v-else-if="item.bid_status.started">
        {{item.bid_status.remaining_seconds > 0 ? $t("ITEM_ROW_END_STATUS_LABEL") : $t("ITEM_ROW_ENDED_STATUS_LABEL")}}
        <span class="auction_end_date">{{item.end_datetime | dateFormat}}</span>
        <span class="auction_end_time">{{item.end_datetime | timeFormat}}</span>
      </p>
      <p class="auction_end" v-else>
        {{$t("ITEM_ROW_START_STATUS_LABEL")}}
        <span class="auction_end_date">{{item.start_datetime | dateFormat}}</span>
        <span class="auction_end_time">{{item.start_datetime | timeFormat}}</span>
      </p>
    </div>
    <div class="txt-container align-items-center u-fw700">
      <p v-if="item.attention_info && item.attention_info.apply_bid_count > 0" class="u-mr-a now_price_h">{{$t("ITEM_ROW_CURRENT_PRICE_LABEL")}}</p>
      <p v-else class="u-mr-a now_price_h">{{$t("ITEM_ROW_START_PRICE_LABEL")}}</p>
      <p v-if="item.sold_out" class="u-ml-a"><span class="now_price">{{$t("ITEM_ROW_SOLD_OUT_LABEL")}}</span></p>
      <p v-else class="u-ml-a"><span class="now_price">{{getPriceIncludeTax(item.bid_status.current_price)}}</span>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</p>
    </div>
    <p v-if="!item.sold_out" class="now_price_tax u-ta-r">{{$t('ITEM_ROW_PRICE_WITHOUT_TAX_LABEL')}}<span>{{item.bid_status ? item.bid_status.current_price.numberString() : ''}}</span>{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</p>
    <div class="horse_li_data">
        <p v-if="item.free_field && String(item.free_field.southkanto_moveable) === '1'">{{$t('ITEM_SEARCH_SOUTHKANTO_MOVEABLE_LABEL') + $t('ITEM_SEARCH_KA_LABEL')}}</p>
        <p v-if="item.free_field && item.free_field.jradirt_win > 0">{{$t('DETAIL_ITEM_JRA_DIRT_LABEL') + item.free_field.jradirt_win + $t('DETAIL_ITEM_JRA_DIRT_WIN_LABEL')}}</p>
        <p v-if="item.free_field && String(item.free_field.foreign_horse) === '1'">{{$t('DETAIL_ITEM_FOREIGN_HORSE_LABEL')}}</p>
        <p v-if="item.free_field.recommend_comment">{{item.free_field.recommend_comment}}</p>
    </div>
  </div>

  <div class="horse_li_bid">
     <v-tooltip top :color="((errors.length > 0 && errors[0].success) ? 'success' : 'error')" v-model="errors.length">
      <template v-slot:activator="{ on, attrs }">
        <div>
          <form>
            <div class="bid_price txt-container align-items-center">
              <p class="bid_price_title u-mr-a">{{$t("ITEM_ROW_BID_PRICE_LABEL")}}</p>
              <div class="bid_input_con">
                <div class="bid_input_btn subtra-btn" v-if="item.bid_status && item.bid_status.can_bid">
                  <a
                    :disabled="item.bid_status && !item.bid_status.can_bid"
                    @click="subtractPitch(item.bid_status.pitch_width)"
                  >
                    <span class="material-symbols-outlined">remove</span>
                  </a>
                </div>
                <input
                  @focus="errorMessages.splice(0, errorMessages.length);errors=[]"
                  v-bind:class="{
                    'bid_input': true,
                    'no-spin': true,
                    'err': errorMessages.length > 0 || (errors.length > 0 && !errors[0].success)
                  }"
                  :disabled="item.bid_status && !item.bid_status.can_bid"
                  v-model="inputBidPrice"
                  type="text"
                  maxLength="9"
                  v-on:keypress="isNumber($event)"
                  v-on:focusin="inputBidPrice = onFocusin_start_price($event)"
                  v-on:focusout="inputBidPrice = onFocusout_start_price($event)"
                >
                <div class="bid_input_btn add-btn" v-if="item.bid_status && item.bid_status.can_bid">
                  <a
                    :disabled="item.bid_status && !item.bid_status.can_bid"
                    @click="addPitch(item.bid_status.pitch_width)"
                  >
                    <span class="material-symbols-outlined">add</span>
                  </a>
                </div>
                <p class="bid_input_en">{{$t("ITEM_ROW_PRICE_CURRENCY_MARK")}}</p>
              </div>
            </div>
            <div class="txt-container align-items-end u-pt10">
              <p class="u-fw400">{{$t('ITEM_ROW_BID_COUNT_LABEL')}}<span class="bid_count">{{item.attention_info.apply_bid_count}}</span></p>
            </div>
            <div class="bid_submit" v-if="item.bid_status && item.bid_status.can_bid">
              <div class="btn_radius">
                <button
                  type="button"
                  :value="$t('ITEM_ROW_BID_BUTTON_LABEL')"
                  class="bid_submit_btn" deta-toggle="modal"
                  @click="bidItemClick(grandParent.typeTag)"
                >
                  {{$t("ITEM_ROW_BID_BUTTON_LABEL")}}
                </button>
              </div>
            </div>
            <div class="bid_submit u-mt10" v-if="item.bid_status && item.bid_status.is_cancel">
              <p class="bid_cancel_txt">{{$t("ITEM_ROW_ITEM_CANCELLED_TEXT")}}</p>
            </div>
          </form>
        </div>
      </template>
      <div :class="errors.length>0 && errors[0].success ? 'custom-tooltip': ''">
        <div style="padding: 10px;" v-if="errors.length>0">
          <div v-for="(message,index) in errors" :key="index">{{message.errorMessage}}</div>
        </div>
      </div>
    </v-tooltip>
  </div>

  <div class="horse_li_link">
    <a @click="$routerGoNewWindow(grandParent.detailPageName, item.exhibition_item_no, {filter:encodeURIComponent(JSON.stringify(parent.filter))})">
      <p>
        {{$t("ITEM_ROW_DETAIL_LINK_LABEL")}}
      </p>
    </a>
  </div>
</li>
</template>
<script>
import CommonFilters from '@/common/commonFilters';
import ExtendCountDown from './ExtendCountDown';
import RowBid from './RowBid.js';

export default {
  components : {
    ExtendCountDown
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    grandParent : {
      type    : Object,
      default : Object
    },
    greatGrandParent : {
      type    : Object,
      default : Object
    },
    search : {
      type    : Object,
      default : Object
    },
    item : {
      type    : Object,
      default : Object
    },
    showErrorsDialog : {
      type    : Function,
      default : Object
    },
    bulkBidding : {
      type    : Boolean,
      default : false
    },
    confirmBidding : {
      type    : Boolean,
      default : false
    },
    removeBulkItemByItemNo : {
      type    : Function,
      default : Object
    },
    updateViewAfterBid : {
      type    : Function,
      default : Object
    },
    errorMessages : {
      type    : Array,
      default : () => []
    },
    favorite : {
      type    : Function,
      default : Object
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    },
    getPriceIncludeTax : {
      type    : Function,
      default : Object
    },
    updateFavoriteChangedItems : {
      type    : Function,
      default : Object
    }
  },
  mixins : [RowBid, CommonFilters],
  data() {
    return {
      errors        : [],
      inputBidPrice : this.getBidPrice()
    }
  },
  watch : {
    inputBidPrice(value) {
      this.item.inputBidPrice = value
      if (value * this.item.bid_status.pitch_width !== this.item.bid_status.bid_price) {
        this.item.changed = true
        this.parent.showBulkBiddingButton = true
      }
    },
    // Update favorite status
    'greatGrandParent.favoriteChangedItems'(fvItems) {
      console.log('Fvorite changed items: ', fvItems)
      this.reloadFavoriteChangedItems(fvItems)
    }
  }
}
</script>
<style scoped lang="scss">
  .unlink {
    cursor: inherit;
  }
  .u-bg-red {
    background-color: #f00;
  }
</style>
<style scoped lang="scss">
  .status .second_status {
    color: #000 !important;
    background-color: #ced2d8 !important;
    text-align: center !important;
    flex-grow: 2;
  }
</style>

<style scoped lang="css">
  @media screen and (min-width: 769px) {
    .row {
      /* margin: 0 -15px !important; */
      margin-top: 0px !important;
      margin-right: inherit !important;
      margin-bottom: 0px !important;
      margin-left: inherit !important;
    }
    .v-application p {
      margin-bottom: inherit !important;
    }
    .horse_li_img {
      min-height: 180px;
    }
  }
  @media screen and (max-width: 768px) {
    #auction .horse_list_card .horse_li_txt .horse_li_prof,
    #auction .horse_list_card .horse_li_txt .auction_end,
    #auction .horse_list_card .horse_li_txt .horse_li_data p,
    #auction .horse_list_card .horse_li_link >>> p,
    #auction .horse_list_card .txt-container >>> p,
    #auction .horse_list_card .horse_li_img >>> p,
    #auction .horse_list_card .horse_li_bid >>> p
    {
      margin-bottom: initial !important;
    }
    .now_price_tax {
      margin-bottom: 30px !important;
    }
  }
  #auction .horse_list_card .horse_li_link a {
    text-decoration: none !important;
  }
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  ul.bid_input_add {
    padding: 0.5rem !important;
  }
  .horse_li_prof span {
    padding-right: 0.5em;
  }
  .horse_li_bid .bid_input:disabled {
    background: #dddddd;
  }
  .bid_submit_btn:disabled {
    background-color: #787878 !important;
    cursor: not-allowed;
  }
  .bid_cancel_txt {
    color: #f00;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .bid_cancel_txt {
      font-size: 1.3em;
    }
  }
  .auction_end_time >>> p.fade {
    color: #000 !important;
    font-size: 1.3em;
  }
  .auction_end_time >>> p.countdown-text {
    color: #000 !important;
    font-size: 1.3em;
  }
  @media screen and (min-width: 769px) {
    .horse_li_base {
      max-height: 200px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 768px) {
    #auction .horse_list_card {
      margin-bottom: 25px;
      border: solid 3px #ffffff;
    }
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
