<template>
<main class="contents_main">
  <div class="account">
    <section id="account-info">
      <div class="account-info">
        <h2>{{$t('MEMBER_EDIT_CONFIRM_EXPLANATION_MESSAGE')}}</h2>
      </div>
    </section>
    <section id="account-data" class="account-data">
      <table class="tbl-account">
        <tbody>
          <tr>
            <th :style="{'vertical-align': 'top'}" v-html="$t('MEMBER_REQUEST_CONFIRM_NICKNAME_LABEL')"></th>
            <td :style="{'overflow-wrap': 'anywhere'}">
              {{parent.registerData.nickname}}
              <p v-if="errors && errors.nickname" class="err-txt">{{errors.nickname}}</p>
              <p v-if="errors && errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NAME_LABEL')"></th>
            <td>
              {{parent.registerData.name}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_FURIGANA_LABEL')"></th>
            <td>
              {{parent.registerData.furigana}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_TYPE_LABEL')"></th>
            <td>
              {{getRegisterTypeName(parent.registerData.type)}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_OWNER_ASSOCIATION_LABEL')"></th>
            <td>
              {{parent.registerData.ownerAssociation || ''}}
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_REPRESENTATIVE_LABEL')}}
              <span class="d-inline-block u-em8">{{$t('MEMBER_REQUEST_REPRESENTATIVE_LABEL2')}}</span>
            </th>
            <td>
              {{parent.registerData.representative || ''}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL')"></th>
            <td>
              {{parent.registerData.postCode}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL')"></th>
            <td>
              {{parent.registerData.address}}
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
              <span class="u-em8">{{$t('MEMBER_REQUEST_FORM_TEL_LABEL2')}}</span>
            </th>
            <td>
              {{parent.registerData.tel || ''}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_FAX_LABEL')"></th>
            <td>
              {{parent.registerData.fax || ''}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_EMAIL_LABEL')"></th>
            <td>
              {{parent.registerData.email}}
              <p v-if="errors && errors.email" class="err-txt">{{errors.email}}</p>
              <p v-if="errors && errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
            </td>
          </tr>
          <tr v-if="parent.registerData.currentPassword">
            <th v-html="$t('MEMBER_REQUEST_CURRENT_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr v-if="parent.registerData.password">
            <th v-html="$t('MEMBER_REQUEST_NEW_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr v-if="parent.registerData.password">
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
            </th>
            <td v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></td>
          </tr>
        </tbody>
      </table>
      <div class="btn-form">
        <input type="button" class="btn-back" :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')" @click="parent.editFlag=true"><input type="button" :value="$t('MEMBER_EDIT_CONFIRM_SEND_BUTTON_LABEL')" @click="registerMemberRequest">
      </div>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods';
export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      errors : {}
    }
  },
  methods : {
    registerMemberRequest() {
      const params = {
        memberRequestNo : this.parent.memberRequestNo || null,
        registerData    : this.parent.registerData
      }
      this.$store.state.loading = true
      Methods.apiExcute('private/change-member-info', this.$i18n.locale, params).then(data => {
        // Update new nickname
        this.$cookies.set(
          this.$define.LOCAL_STORE_LABEL.LOGIN_USER_NAME,
          this.parent.registerData.nickname,
          '0',
          null,
          null,
          Methods.boolHttpsProtocol()
        )
        this.$store.commit('nickNameChanged')

        this.$store.state.loading = false
        /*
         * This.parent.editFlag = true
         * this.parent.registerData = {}
         */
        this.parent.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Object.assign(Methods.parseHtmlResponseError(this.$router, error), this.errors)
        })
    },
    getRegisterTypeName(typeId) {
      let ret = ''
      const typeElm = this.parent.constants.find(x => x.key_string === 'MEMBER_TYPE' && String(x.value1) === String(typeId))
      if (typeElm) {
        ret = typeElm.value2
      }
      return ret
    }
  }
}
</script>
<style scoped lang="scss">
  @media screen and (min-width: 770px){
    .tbl-entry td {
      height: 3em;
    }
  }
  p.err-txt {
    color: #f00;
  }
  .account-info h2 {
    font-size: 1.6em !important;
  }
</style>
<style scoped src="@/assets/css/contents02.css"></style>
