<template>
<div>
  <Register v-if="editFlag" :parent="$data" />
  <Completion v-else-if="completedFlag" :parent="$data" />
  <Comfirm v-else :parent="$data" />
</div>
</template>

<script>
import Methods from '@/api/methods'
import Comfirm from '../../components/ChangeInfo/Comfirm'
import Completion from '../../components/ChangeInfo/Completion'
import Register from '../../components/ChangeInfo/Register'

export default {
  components : {
    Comfirm,
    Completion,
    Register
  },
  data() {
    return {
      mode            : 'ChangeInfo',
      registerData    : {},
      constants       : [],
      memberId        : null,
      memberRequestNo : null,
      editFlag        : true,
      selectedCountry : {},
      filesName       : [],
      confirmPassword : null,
      completedFlag   : false,
    }
  },
  methods : {
    getContactConstants() {
      this.$store.state.loading = true

      Methods.apiExcute('private/get-change-info-constants', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.constants = data.constants

        // 退会した、データが見つからない場合はログインページに戻る
        if (data.memberId) {
          this.memberId = data.memberId
          this.memberRequestNo = data.memberRequestNo
          if (data.member) {
            this.registerData = Object.assign({}, data.member, this.registerData)
          }
          this.filesName = data.member.files
            ? [].concat(data.member.files).map(x => {
              return {key : x}
            })
            : []
          this.files = data.member.files ? [].concat(data.member.files) : []
        } else {
          this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
          this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
        }
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
  created() {
    this.getContactConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.getContactConstants()
    },
    registerData(value) {
      if (Object.keys(value).length === 0) {
        this.getContactConstants()
        this.confirmPassword = null
      }
    }
  }
}
</script>

<style scoped src="@/assets/css/contents02.css"></style>
