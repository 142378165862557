import moment from 'moment'
moment.locale('ja')

export default {
  filters : {
    dateTimeDayFormat(date, format = 'YYYY年M月D日（ddd）H:mm') {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format(format)
      return year
    },
    dateTimeFormat(date) {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format('YYYY年M月D日 H:mm')
      return year
    },
    dateFormat(date) {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const year = moment(isoYear).format('YYYY年M月D日')
      return year
    },
    timeFormat(date, format = 'H:mm') {
      if (!date) {
        return ''
      }
      const isoYear = new Date(date).toISOString()
      const time = moment(isoYear).format(format)
      return time
    },
    // 総賞金、地方・中央賞金
    prizeMoney2String(val) {
      if (val && !isNaN(val)) {
        const tmp = Number(val).toFixed(1)
        return Number.isInteger(Number(tmp)) ? (`${Number(tmp).toLocaleString()}.0`) : Number(tmp).toLocaleString()
      } else {
        return (val || '')
      }
    },
    // 値が「-」(数値以外)や空文字の場合は単位を表示しないようにする
    number2string(val, nullText = '', unit = '') {
      if (typeof val === 'undefined' || val === null || String(val).length === 0) {
        return nullText
      }
      return isNaN(val) ? String(val) : (val.numberString() + unit)
    },
    getFullImagePath(file_path) {
      return `${process.env.VUE_APP_API_ENDPOINT.replace('/api/', '')}/${encodeURIComponent(file_path)}`
    }
  }
}
