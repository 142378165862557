import { render, staticRenderFns } from "./withdrawDialog.vue?vue&type=template&id=ef0b3a0c&scoped=true"
import script from "./withdrawDialog.vue?vue&type=script&lang=js"
export * from "./withdrawDialog.vue?vue&type=script&lang=js"
import style0 from "./withdrawDialog.vue?vue&type=style&index=0&id=ef0b3a0c&prod&scoped=true&lang=scss"
import style1 from "@/assets/css/top.css?vue&type=style&index=1&id=ef0b3a0c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef0b3a0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VContainer,VDialog})
