import Methods from '@/api/methods'
import Base from '@/common/base'
export default {
  data() {
    // Const limitPerPages = [100, 200]
    return {
      items         : [],
      limitPerPages : this.$define.LIMIT_PER_PAGE,
      filter        : {
        category            : [],
        exhibition_no       : null,
        southkanto_moveable : null,
        bad_habit           : null,
        injury_history      : null,
        timeover            : null,
        favorite            : null,
        limit               : this.$define.LIMIT_PER_PAGE[0],
        initLimit           : this.$define.LIMIT_PER_PAGE[0]
      },
      errors                : [],
      filteredObject        : {},
      categoryResultCount   : null,
      isMoreLimit           : false,
      itemsCount            : 0,
      itemsCountInitial     : 0,
    }
  },
  created() {
    try {
      console.log('this.$route.query: ', this.$route.query)
      console.log('this.$route.params: ', this.$route.params)
      if (this.$route.query && this.$route.query.filter) {
        console.log('filter before: ', this.filter)
        this.filter = Object.assign(this.filter, JSON.parse(decodeURIComponent(this.$route.query.filter)))
        console.log('filter after: ', this.filter)
      } else if (this.$route.params.id) {
        this.filter.exhibitionItemNos = [this.$route.params.id]
      }
      if (!this.$route.params.id) {
        delete this.filter.exhibitionItemNos
        delete this.filter.recommending
      }
      if (this.extraFilter) {
        this.filter = Object.assign({}, this.filter, this.extraFilter)
      }
    } catch (e) {
      console.log(e)
    }
    if (this.grandParent.isConstantLoaded) {
      if (this.$route.meta.enableQuery) {
        // Search 画面の時
        this.initSearch()
      } else {
        // Detail 画面の時
        this.searchItems()
      }
    }
  },
  methods : {
    searchItems(showedItemNos = null) {
      this.$store.state.loading = true
      const params = this.getSearchItemsRequestParams(this.filter, showedItemNos)
      this.search(params).then(data => {
        this.$store.state.loading = false
        this.isMoreLimit = data.isMoreLimit
        this.itemsCount = data.count
        this.itemsCountInitial = data.count_init
        if (data.moreSearch) {
          Array.prototype.push.apply(this.items, data.items)
          this.filter.initLimit = this.items.length

          // Get item info in case of moving from lineup item
          if (!this.item || !this.item.item_no) {
            this.getCurrentItem()
          }
        } else {
          this.items = data.items
          if (this.$route.params.id) {
            this.getCurrentItem()
          }
        }
        this.categoryResultCount = this.grandParent.categoryConstants.map((row, index) => {
          const category = data.category_group.find(category => String(category.category_id) === String(this.grandParent.categoryConstants[index].value1))
          return category ? category.count : 0
        })

        this.filteredObject = JSON.parse(JSON.stringify(this.filter))
        /*
         * // Urlにfilterを表示するため、また$route.query.filterを設定するため
         * if (this.$route.meta.enableQuery) {
         *   this.$routerGo(this.$route.name, null, {
         *     filter : encodeURIComponent(JSON.stringify(this.filter))
         *   })
         * }
         */
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    // 画面に検索条件を表示するため
    getFiltered(filter) {
      let tmp_idx = -1
      const filtered = []
      // Filtered.push(this.$t(this.parent.typeTag))
      if (filter.category) {
        filter.category.forEach((element, index) => {
          if (element) {
            filtered.push(this.grandParent.categoryConstants[index].value2)
          }
        })
      }
      if (filter.southkanto_moveable) {
        tmp_idx = this.grandParent.kahiConstants.findIndex(x => x.value1 === filter.southkanto_moveable)
        if (tmp_idx !== -1) {
          filtered.push(this.$t('ITEM_SEARCH_SOUTHKANTO_MOVEABLE_LABEL') + this.grandParent.kahiConstants[tmp_idx].value2)
        }
      }
      if (filter.bad_habit) {
        tmp_idx = this.grandParent.umuConstants.findIndex(x => x.value1 === filter.bad_habit)
        if (tmp_idx !== -1) {
          filtered.push(this.$t('ITEM_SEARCH_BAD_HABIT_LABEL') + this.grandParent.umuConstants[tmp_idx].value2)
        }
      }
      if (filter.injury_history) {
        tmp_idx = this.grandParent.umuConstants.findIndex(x => x.value1 === filter.injury_history)
        if (tmp_idx !== -1) {
          filtered.push(this.$t('ITEM_SEARCH_INJURY_HISTORY_LABEL') + this.grandParent.umuConstants[tmp_idx].value2)
        }
      }
      if (filter.timeover) {
        tmp_idx = this.grandParent.umuConstants.findIndex(x => x.value1 === filter.timeover)
        if (tmp_idx !== -1) {
          filtered.push(this.$t('ITEM_SEARCH_TIMEOVER_LABEL') + this.grandParent.umuConstants[tmp_idx].value2)
        }
      }
      if (filter.favorite) {
        tmp_idx = this.grandParent.umuConstants.findIndex(x => x.value1 === filter.favorite)
        if (tmp_idx !== -1) {
          filtered.push(this.$t('ITEM_SEARCH_FAVORITE_LABEL') + this.grandParent.umuConstants[tmp_idx].value2)
        }
      }
      if (filter.bidding) {
        filtered.push(this.$t('ITEM_SEARCH_BIDDING_LABEL'))
      }
      if (filter.exceedingLowestPrice) {
        filtered.push(this.$t('ITEM_SEARCH_EXCEEDING_THE_LOWEST_BID_LABEL'))
      }
      if (filter.soldOut) {
        filtered.push(this.$t('ITEM_SEARCH_SOLD_OUT_LABEL'))
      }
      if (filter.recommending) {
        filtered.push(this.$t('ITEM_SEARCH_RECOMMEND_LABEL'))
      }
      return filtered
    },
    getSearchItemsRequestParams(filter, showedItemNos = null) {
      console.log('filter: ', filter)
      const params = Object.assign({}, filter, {
        showedItemNos,
        exhibition_no       : filter.exhibition_no,
        startPrice          : Number(filter.startPrice) * 10000,
        endPrice            : Number(filter.endPrice) * 10000,
        limit               : showedItemNos ? filter.limit : filter.initLimit,
        category            : filter.category ? filter.category : null,
        favorite            : filter.favorite === null ? null : (filter.favorite === '1'),
        southkanto_moveable : filter.southkanto_moveable === null ? null : (filter.southkanto_moveable === '1'),
        bad_habit           : filter.bad_habit === null ? null : (filter.bad_habit === '1'),
        injury_history      : filter.injury_history === null ? null : (filter.injury_history === '1'),
        timeover            : filter.timeover === null ? null : (filter.timeover === '1')
      })
      delete params.categoryExtension
      console.log('params: ', params)
      return params
    },
    initSearch() {
      console.log('initSearch')
      this.filter.category = []
      this.exhibition_no = null
      this.filter.southkanto_moveable = null
      this.filter.bad_habit = null
      this.filter.injury_history = null
      this.filter.timeover = null
      this.filter.favorite = null
      this.searchItems()
    },
    updateViewAfterBid(bidList) {
      this.showBulkBiddingButton = false
      this.items.map(item => {
        const bid = bidList.find(bid => String(item.exhibition_item_no) === String(bid.exhibition_item_no))
        if (bid) {
          item.bid_status = Object.assign({}, item.bid_status, bid)
          if (this.removeBulkItemByItemNo) {
            this.removeBulkItemByItemNo(item.exhibition_item_no)
          }
          item.attention_info.bid_count += 1
        }
        if (item.changed) {
          this.showBulkBiddingButton = true
        }
        return item
      })
    },
    getCurrentItem() {
      this.itemIndex = this.items.findIndex(item => String(item.exhibition_item_no) === String(this.$route.params.id))
      if (this.itemIndex === -1) {
        if (this.filter.exhibitionItemNos) {
          alert('不正なURLです。')
          this.$routerGo(this.grandParent.searchPageRouterName, null, {filter : this.filter})
        } else {
          /*
           * Detail画面に移動するがexhibition_item_noが見つからなかった時に
           * this.$routerGo(this.$route.name, this.$route.params.id)
           */
          this.filter.exhibitionItemNos = [this.$route.params.id]
          this.initSearch()
        }
      } else {
        this.item = this.items[this.itemIndex]
        this.inputBidPrice = this.getBidPrice()
        if (this.itemIndex === this.items.length - 1 && this.isMoreLimit) {
          this.searchItems(this.items.map(item => item.exhibition_item_no))
        }
        this.refreshItemForDetail()
      }
    },
    searchParamChanged() {
      this.searchItems()
    },
    getPriceIncludeTax(price, tax_rate = null) {
      const tax = tax_rate || (this.grandParent.taxRateConstants ? this.grandParent.taxRateConstants[0].value1 : 0)
      const price_s2n = Base.localeString2Number(price)
      const taxed = price_s2n + (price_s2n * tax / 100)
      return taxed.numberString()
    }
  },
  watch : {
    '$i18n.locale'() {
      if (this.$route.meta.enableQuery) {
        this.getItemSearchConstants()
      } else {
        this.searchItems()
      }
    },
    '$route.query.filter'(value) {
      try {
        if (value) {
          const newFilter = JSON.parse(decodeURIComponent(JSON.stringify(value)))
          console.log('watch filter: ', newFilter)
          if (typeof newFilter.category === 'undefined') {
            this.filter = Object.assign({
              category            : [],
              exhibition_no       : null,
              southkanto_moveable : null,
              bad_habit           : null,
              injury_history      : null,
              timeover            : null,
              favorite            : null,
              limit               : this.$define.LIMIT_PER_PAGE[0],
              initLimit           : this.$define.LIMIT_PER_PAGE[0]
            }, JSON.parse(decodeURIComponent(value)))
            this.initSearch()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    'grandParent.isConstantLoaded'(value) {
      console.log('isConstantLoaded: ', value)
      if (value) {
        if (this.$route.meta.enableQuery) {
          // Search 画面の時
          this.initSearch()
        } else {
          // Detail 画面の時
          this.searchItems(this.filter.showedItemNos)
        }
      }
    }
  }
}
