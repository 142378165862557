<template>
<main class="contents_main">
  <div class="account">
    <section id="account-info">
      <div class="account-info u-mt30">
        <h2>{{$t('MEMBER_EDIT_COMPLETION_MESSAGE')}}</h2>
        <div class="btn-form u-mt30 u-mb30">
          <input
            type="button"
            :value="$t('REISSUE_PASSWORD_COMPLETION_GO_TO_TOP_BUTTON_LABEL')"
            @click="$routerGo($define.PAGE_NAMES.TOP_PAGE_NAME)"
            class="u-fs14 u-bg-red"
            tabindex="0">
        </div>
      </div>
    </section>
  </div>
</main>
</template>

<script>

export default {
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  }
}
</script>
<style scoped lang="css">
  .auction_guide_link {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .account-info h2 {
    font-size: 1.6em !important;
  }
</style>
<style scoped src="@/assets/css/contents02.css"></style>
