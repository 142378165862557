<template>
<main class="contents_main">
  <div class="account">
    <section id="account-info">
      <Header />
      <div class="account-info">
        <h2>{{$t('MEMBER_PAGE_CHANGE_TITLE')}}</h2>
      </div>
    </section>
    <section id="account-data" class="account-data">
      <table class="tbl-account">
        <tbody>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_NICKNAME_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="parent.registerData.nickname" v-bind:class="{'err': errors.nickname}" @keydown="errors.nickname=null" ref="nickname">
              <p v-if="errors.nickname" class="err-txt">{{errors.nickname}}</p>
              <p v-if="errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_NAME_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="parent.registerData.name" v-bind:class="{'err': errors.name}" @keydown="errors.name=null" ref="name">
              <p class="err-txt">{{errors.name}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_FURIGANA_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="parent.registerData.furigana" v-bind:class="{'err': errors.furigana}" @keydown="errors.furigana=null" ref="furigana">
              <p class="err-txt">{{errors.furigana}}</p>
            </td>
          </tr>
          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_TYPE_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td class="form-select">
              <select required v-model="parent.registerData.type" v-bind:class="{'err': errors.registerType}" ref="type">
                <option
                  v-for="constant in parent.constants.filter(constant => constant.key_string == 'MEMBER_TYPE')"
                  :value="constant.value1"
                  :label="constant.value2"
                  selected="selected"
                  :key="constant.value1">{{constant.value2}}</option>
              </select>
              <!-- <p class="err-txt">{{errors.type}}</p> -->
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_OWNER_ASSOCIATION_LABEL')}}
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="parent.registerData.ownerAssociation" v-bind:class="{'err': errors.ownerAssociation}" @keydown="errors.ownerAssociation=null" ref="ownerAssociation">
              <p class="err-txt">{{errors.ownerAssociation}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_REPRESENTATIVE_LABEL')}}
              <span class="d-inline-block u-em8">{{$t('MEMBER_REQUEST_REPRESENTATIVE_LABEL2')}}</span>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="parent.registerData.representative" v-bind:class="{'err': errors.representative}" @keydown="errors.representative=null" ref="representative">
              <p class="err-txt">{{errors.representative}}</p>
            </td>
          </tr>
          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_POST_CODE_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td class="u-pb0">
              <div class="ipt-wrap">
                <input
                  type="text"
                  class="ime-dis iptW-S"
                  v-model="parent.registerData.postCode"
                  @keydown="errors.postCode=null"
                  required v-bind:class="{'err': errors.postCode}"
                  ref="postCode" />
                <input class="ipt-btn-gray zip-search" type="button" :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')" @click="searchPostCode">
                <p class="err-txt">{{errors.postCode}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_EDIT_FORM_ADDRESS_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="parent.registerData.address" v-bind:class="{'err': errors.address}" @keydown="errors.address=null" ref="address">
              <p class="err-txt">{{errors.address}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
              <span class="u-em8">{{$t('MEMBER_REQUEST_FORM_TEL_LABEL2')}}</span>
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER')" required v-model="parent.registerData.tel" v-bind:class="{'err': errors.tel}" @keydown="errors.tel=null" ref="tel">
              <p class="err-txt">{{errors.tel}}</p>
            </td>
          </tr>
          <tr>
            <th>{{$t('MEMBER_REQUEST_FORM_FAX_LABEL')}}</th>
            <td>
              <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_FAX_NUMBER_PLACEHOLDER')" v-model="parent.registerData.fax" v-bind:class="{'err': errors.fax}" @keydown="errors.fax=null" ref="fax">
              <p class="err-txt">{{errors.fax}}</p>
            </td>
          </tr>

          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_EMAIL_LABEL')}}<br />
              <span class="u-em8">{{$t('MEMBER_REQUEST_FORM_EMAIL_LABEL2')}}</span>
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="email" class="ime-dis iptW-M" required v-model="parent.registerData.email" v-bind:class="{'err': errors.email}" @keydown="errors.email=null" ref="email">
              <p v-if="errors.email" class="err-txt">{{errors.email}}</p>
              <p v-if="errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
            </td>
          </tr>

          <tr>
            <th v-html="$t('MEMBER_REQUEST_CURRENT_PASSWORD_LABEL')"></th>
            <td>
              <input :type="currentPasswordType" class="ime-dis iptW-M password" required v-model="parent.registerData.currentPassword" v-bind:class="{'err': errors.currentPassword}" @keydown="errors.currentPassword=null" ref="currentPassword" autocomplete="new-password">
              <span @click="showHidePassword(1)" class="show-password"><Icon :icon="currentPasswordType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
              <p class="err-txt">{{errors.currentPassword}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_NEW_PASSWORD_LABEL')}}
            </th>
            <td>
              <input :type="passwordType" class="ime-dis iptW-M password" required v-model="parent.registerData.password" v-bind:class="{'err': errors.password}" @keydown="errors.password=null" ref="password" autocomplete="new-password">
              <span @click="showHidePassword(2)" class="show-password"><Icon :icon="passwordType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
              <p class="err-txt">{{errors.password}}</p>
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL')"></th>
            <td>
              <input :type="passwordConfirmType" class="ime-dis iptW-M password" required v-model="parent.confirmPassword" v-bind:class="{'err': errors.confirmPassword}" @keydown="errors.confirmPassword=null" ref="confirmPassword" autocomplete="new-password">
              <span @click="showHidePassword(3)" class="show-password"><Icon :icon="passwordConfirmType === 'password' ? 'akar-icons:eye' : 'akar-icons:eye-slashed'" style="vertical-align: middle;"/></span>
              <p class="err-txt">{{errors.confirmPassword}}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="form-privacy">
        {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
        <a @click="$routerGoNewWindow($define.PAGE_NAMES.PRIVACY_PAGE_NAME, null, {lang: $i18n.locale})">{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2')}}</a>
        {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
      </p>
      <table class="tbl-entry tbl-privacy">
        <tr>
          <th>
            {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
            <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
          </th>
          <td class="privacy-chk">
            <label for="rule-chk">
              <input type="checkbox" id="rule-chk" class="checkbox-input" @click="ruleCheckClick" required>
              <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
            </label>
          </td>
        </tr>
      </table>
      <div class="btn-form">
        <input type="button" :value="$t('MEMBER_REQUEST_FORM_SUBMIT_LABEL')" :disabled="!ruleCheckBtnClicked" @click="validate">
      </div>
    </section>
    <div class="contents">
      <div class="row gutters-0">
        <div class="btn-form mypage_link">
          <input class="withdraw" type="button" :value="$t('MEMBER_REQUEST_FORM_WITHDRAW_LABEL')" @click="withdrawMemberComfirmDialog=true">
        </div>
      </div>
    </div>
  </div>

  <WithdrawDialog
    :parent="$data"
    :withdrawMemberComfirmDialog="withdrawMemberComfirmDialog"
    :closeWithdrawDialog="closeWithdrawDialog"
    :withdrawMember="withdrawMember"
  />

</main>
</template>

<script>
import Methods from '@/api/methods'
import {Icon} from '@iconify/vue2'
import Header from '../MyPage/Header'
import RegisterLogic from '../RegisterMember/RegisterLogic.js'
import WithdrawDialog from './withdrawDialog'
export default {
  components : {
    Icon,
    Header,
    WithdrawDialog
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
      scripts                     : [],
      errors                      : {},
      withdrawMemberComfirmDialog : false
    }
  },
  mixins  : [RegisterLogic],
  methods : {
    request(params) {
      return Methods.apiExcute('private/change-member-info', this.$i18n.locale, params)
    },
    withdrawMember() {
      this.$store.state.loading = true
      Methods.apiExcute('private/withdraw-member', this.$i18n.locale).then(data => {
        this.$store.state.loading = false
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
        this.$routerGo(this.$define.PAGE_NAMES.WITHDRAW_SUCCESS_PAGE_NAME)
      })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    extraValidate() {
      if (this.parent.registerData.password) {
        if (!this.parent.confirmPassword) {
          this.errors.confirmPassword = this.$t('MEMBER_INFO_EMPTY_MESSAGE')
        } else if (this.parent.registerData.password !== this.parent.confirmPassword) {
          this.errors.confirmPassword = this.$t('PASSWORD_CONFIRM_ERROR_MESSAGE')
        }
        if (!this.parent.registerData.currentPassword) {
          this.errors.currentPassword = this.$t('CURRENT_PASSWORD_ERROR_MESSAGE')
        }
      }
    },
    closeWithdrawDialog() {
      this.withdrawMemberComfirmDialog = false
    }
  }
}
</script>
<style scoped lang="css">
  .v-application p {
    margin-bottom: initial !important;
  }
</style>
<style scoped lang="scss">
  p.err-txt {
    color: #f00;
  }
  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .disabled {
    background-color: #c4c4c4;
  }
  .border-bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .text-center {
    text-align: center;
  }
</style>
<style lang="scss">
  .remodal-wrapper:after {
    vertical-align: middle;
  }
  .fontB {
    font-weight: 700!important;
  }
</style>
<style scoped lang="css">
  @media screen and (max-width: 767px) {
    .tbl-account td {
      width: 100% !important;
      padding: 1.3rem 0.5em !important;
    }
  }
  @media screen and (min-width: 768px) {
    .tbl-account td {
      padding: 1.3rem 0 1.3rem 1.5rem !important;
    }
    .tbl-account th {
      padding: 0 5em 0 0 !important;
    }
  }
  .tbl-account td input, .tbl-account td select {
    border: 1px solid #e4e4e4;
    padding: 0rem 2rem !important;
    height: 2.5em;
  }
  .u-fw600{font-weight:600!important}
  .u-fw700{font-weight:700!important}
  .tbl-account >>> em.req {
    position: absolute;
    color: #e80000;
    right: 1rem;
    font-size: 0.8em;
    padding: 0.4em 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .tbl-account td.form-select{
    position: relative;
  }
  .tbl-account td.form-select:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-top: 8px solid #7d7d7d;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none;
  }
  .tbl-account td input.iptW-S {
    max-width: 240px;
  }
  .tbl-account td input.zip-search {
    margin: 0 0 0 15px;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: #3C85C6;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    max-width: 100px;
    -webkit-transition: all 0.08s linear;
    transition: all 0.08s linear;
  }
  @media screen and (max-width: 769px) {
    .show-password {
      vertical-align: 10%;
    }
  }
  .v-dialog__content {
    z-index: 1000 !important;
  }
  .mypage_link a {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  /* .tbl-entry th em.req {
    background-color: #e80000 !important;
    color: #fff !important;
  } */
  .transfer-btn[disabled], .transfer-btn[disabled]:hover {
    background-color: #ccc;
    color: #3C85C6;
    cursor: not-allowed !important;
  }
  .btn-form .withdraw {
    border: 1px solid #0862A5;
    color: #3C85C6 !important;
    background-color: #fff !important;
  }
</style>
<style scoped src="@/assets/css/contents02.css"></style>
